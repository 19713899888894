import { checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import { getContactsData } from "selectors/contacts";
import { getCurrenciesData } from "selectors/metaData";
import {
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getSelectedBusinessCategories,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessItems,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { recalculateLineItemFields, updateState } from "./utils";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentContext from "contexts/DocumentContext";
import Documents from "utils/Documents";
import IntegrationServicesCategories from "const/IntegrationServicesCategories";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";
import moment from "moment";
import parseMoney from "parse-money";

const {
  DOCUMENT_PAYMENT_TYPES,
  CONTACT_SUB_TYPES
} = DataConstants;

const Provider = (props) => {
  const {
    children,
    rootRef,
    attachment,
    state,
    state: {
      lineItems = [],
      amountBase,
      amountVat,
      paymentType,
      type,
      detailedTaxRates,
      currency
    },
    documentFrozen,
    documentData,
    documentData: { status },
    recogniseData,
    onChange,
    onPairedTransactionClick
  } = props;

  const businessUser = useSelector(checkIsBusinessUser);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const contactsData = useSelector(getContactsData);

  const classes = useSelector(getSelectedBusinessClasses);

  const projects = useSelector(getSelectedBusinessProjects);

  const locations = useSelector(getSelectedBusinessLocations);

  const items = useSelector(getSelectedBusinessItems);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const { countryCode } = useSelector(getActiveOrganization);

  const { extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const [localState, setState] = useState({});

  const currenciesData = useSelector(getCurrenciesData);

  const categories = useSelector(getSelectedBusinessCategories);

  const setLocalState = useCallback((update) => {
    setState((prev) => {
      return { ...prev, ...(typeof update === "function" ? update(prev) : update) };
    });
  }, []);

  const lineItemsValid = useMemo(() => {
    return lineItems.every(({ description, amountTotal = null, item = {}, category = {} }) => {
      return description && amountTotal !== null && (businessUser || item.id || category.code);
    });
  }, [businessUser, lineItems]);

  const setDocumentState = useCallback((update) => {
    onChange((currentState) => {
      return updateState(typeof update === "function" ? update(currentState) : update, currentState, {
        recogniseData,
        countryCode,
        quickBooksBusiness,
        xeroBusiness,
        integrationService,
        contactsData,
        categories,
        status
      });
    });
  }, [
    onChange,
    recogniseData,
    countryCode,
    quickBooksBusiness,
    xeroBusiness,
    integrationService,
    contactsData,
    categories,
    status
  ]);

  const updateLineItem = useCallback((uuid, fieldName, value) => {
    setDocumentState((prev) => ({
      lineItems: prev.lineItems.map((lineItem) =>
        lineItem.uuid === uuid
          ? recalculateLineItemFields(lineItem, fieldName, value, { xeroBusiness, classes, taxRates }) : lineItem)
    }));
  }, [setDocumentState, xeroBusiness, classes, taxRates]);

  const lineItemsData = useMemo(() => {
    return Documents.calculateLineItemsData({ lineItems, paymentType, countryCode, quickBooksBusiness });
  }, [countryCode, lineItems, paymentType, quickBooksBusiness]);

  const ocrMeta = useMemo(() => {
    if (!type || !paymentType) return recogniseData?.ocrMeta || {};

    const { sender, recipient } = recogniseData.ocrMeta?.addresses || {};

    if (!type || !paymentType) return recogniseData?.ocrMeta || {};

    return {
      ...(recogniseData?.ocrMeta || {}),
      address: paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY ? sender?.name : recipient?.name
    };
  }, [paymentType, recogniseData?.ocrMeta, type]);

  const filteredContactsData = useMemo(() => {
    const buyPaymentType = paymentType === DOCUMENT_PAYMENT_TYPES.BUY;

    const onlyVendors = paymentType && buyPaymentType && integrationService && !xeroBusiness;

    const onlyCustomers = paymentType && !buyPaymentType && integrationService && !xeroBusiness;

    return contactsData.filter(({ subType }) => {
      return !subType || (!!onlyCustomers === !!onlyVendors)
        || (onlyCustomers && subType === CONTACT_SUB_TYPES.CUSTOMER) || (onlyVendors && subType === CONTACT_SUB_TYPES.VENDOR);
    });
  }, [contactsData, integrationService, paymentType, xeroBusiness]);

  const filteredItems = useMemo(() => {
    return items.filter(({ salesItem, purchaseItem }) => {
      return !xeroBusiness || (paymentType === DOCUMENT_PAYMENT_TYPES.BUY ? purchaseItem : salesItem);
    });
  }, [items, paymentType, xeroBusiness]);

  const filteredCategories = useMemo(() => {
    return categories.filter(({ group }) => {
      return !paymentType || IntegrationServicesCategories.checkIsAvailable(integrationService, group, paymentType);
    });
  }, [categories, integrationService, paymentType]);

  const normalizeFieldValue = useCallback((fieldName, text) => {
    switch (fieldName) {
      case "amount":
      case "amountVat":
      case "amountBase":
      case "detailedTaxRate": {
        let parsed = parseMoney(text);

        if (!parsed?.currency && currency) {
          parsed = parseMoney(`${text} ${currency}`);
        }

        return parsed?.amount || "";
      }
      case "quantity":
      case "vatRate":
        return parseFloat(text);
      case "address": {
        const lowercased = text && text.toLocaleLowerCase();

        return filteredContactsData.find(({ name }) => name && (name.toLocaleLowerCase() === lowercased)) || { name: text };
      }
      case "class": {
        const lowercased = text && text.toLocaleLowerCase();

        return classes.find(({ name }) => name && (name.toLocaleLowerCase() === lowercased)) || { name: text };
      }
      case "project": {
        const lowercased = text && text.toLocaleLowerCase();

        return projects.find(({ name }) => name && (name.toLocaleLowerCase() === lowercased)) || { name: text };
      }
      case "item": {
        const lowercased = text && text.toLocaleLowerCase();

        return filteredItems.find(({ name }) => name && (name.toLocaleLowerCase() === lowercased)) || { name: text };
      }
      case "location": {
        const lowercased = text && text.toLocaleLowerCase();

        return locations.find(({ name }) => name && (name.toLocaleLowerCase() === lowercased)) || { name: text };
      }
      case "category": {
        const lowercased = text && text.toLocaleLowerCase();

        return filteredCategories
          .find(({ displayName }) => displayName && (displayName.toLocaleLowerCase() === lowercased)) || { name: text };
      }
      case "issueDate":
      case "dueDate":
      case "paymentDate": {
        const momentDate = moment(Utils.parseDate(text, countryCode, currency));

        return momentDate.isValid() ? momentDate.format(Constants.DATETIME_FORMATS.API_DATE) : "";
      }
      case "currency": {
        let parsed = parseMoney(text);

        let found = Utils.arrayFind(currenciesData, "code", parsed?.currency);

        if (found) return parsed?.currency;

        parsed = parseMoney(`1 ${text}`);

        found = Utils.arrayFind(currenciesData, "code", parsed?.currency);

        return found ? parsed?.currency : null;
      }
      default:
        return text;
    }
  }, [
    classes,
    countryCode,
    currenciesData,
    currency,
    filteredCategories,
    filteredContactsData,
    filteredItems,
    locations,
    projects
  ]);

  const setValueFromDocument = useCallback((fieldId, text) => {
    const [firstPart, secondPart] = fieldId.split(".") || [];

    const value = normalizeFieldValue(firstPart, text);

    if (value !== null) {
      if (firstPart === "detailedTaxRate") {
        setDocumentState({
          detailedTaxRates: Utils.arrayUpdateItemById(detailedTaxRates, secondPart, (prev) => {
            return { ...prev, value };
          })
        });
      } else if (secondPart) {
        updateLineItem(secondPart, firstPart, value);
      } else {
        setDocumentState({ [firstPart]: value });
      }
    }
  }, [detailedTaxRates, normalizeFieldValue, setDocumentState, updateLineItem]);

  const contextValue = {
    lineItemsAmountGreaterThanDocument: lineItemsData.amountBase > amountBase,
    lineItemsAmountLessThanDocument: lineItemsData.amountBase < amountBase,
    lineItemsAmountVatGreaterThanDocument: !detailedTaxRates.length && lineItemsData.amountVat > amountVat,
    lineItemsAmountVatLessThanDocument: !detailedTaxRates.length && lineItemsData.amountVat < amountVat,
    lineItemsValid,
    localState,
    rootRef,
    documentData,
    recogniseData,
    attachment,
    documentState: state,
    ocrMeta,
    documentFrozen,
    setLocalState,
    updateLineItem,
    setDocumentState,
    setValueFromDocument,
    onPairedTransactionClick
  };

  return (
    <DocumentContext.Provider value={contextValue}>
      {children}
    </DocumentContext.Provider>
  );
};

export default Provider;
