import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { FormElement } from "nlib/common/Form";
import { checkContactsFetching } from "selectors/contacts";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Confidence from "./lib/Confidence";
import ContactFormBlock from "./lib/ContactFormBlock";
import ContactsActions from "actions/ContactsActions";
import DataConstants from "const/DataConstants";
import DatePickerInput from "nlib/common/DatePickerInput";
import DocumentTypeSelect from "./lib/DocumentTypeSelect";
import FormContext from "contexts/FormContext";
import Input from "nlib/ui/Input";
import React, { useCallback, useContext, useMemo, useState } from "react";
import SelectContactInput from "nlib/common/SelectContactInput";
import SelectCurrencyInput from "nlib/common/SelectCurrencyInput";
import SelectLocationInput from "nlib/common/SelectLocationInput";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const {
  DOCUMENT_PAYMENT_TYPES: { BUY },
  CONTACT_SUB_TYPES: { VENDOR, CUSTOMER }
} = DataConstants;

const DocumentTab = (props) => {
  const { lineItemsValid, documentFrozen, documentData } = props;

  const dispatch = useDispatch();

  const { disabled, values, onChange } = useContext(FormContext);

  const {
    issueDate,
    dueDate,
    type,
    paymentType,
    amountBase,
    amountVat,
    vendorId,
    address = {}
  } = values;

  const { id: locationId, name: locationName } = values.location || {};

  const { uiTexts } = useSelector(getTextsData);

  const fetchingContacts = useSelector(checkContactsFetching);

  const {
    settings: { allowClientLocationSelection, allowClientPayeeSelection } = {},
    extraData: { quickBooksLocations } = {}
  } = useSelector(getSelectedBusinessData);

  const [contactAutocompleteOpened, setContactAutocompleteOpened] = useState(false);

  const [contactFormShown, setContactFormShown] = useState(false);

  const receiptType = type === DataConstants.DOCUMENT_TYPES.RECEIPT;

  const salesReceiptType = type === DataConstants.DOCUMENT_TYPES.SALES_RECEIPT;

  const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

  const hasAddressName = !!(address.name || "").trim();

  const locations = useMemo(() => {
    return quickBooksLocations || [];
  }, [quickBooksLocations]);

  const showLocations = allowClientLocationSelection && !!locations.length;

  const addressConfidenceDataPath = `addresses.${buyPaymentType ? "sender" : "recipient"}`;

  const addressConfidenceFieldsNames = documentData.vendorId && vendorId
    ? [`${addressConfidenceDataPath}.name`, `${addressConfidenceDataPath}.idNumber`, `${addressConfidenceDataPath}.vatId`]
    : [`${addressConfidenceDataPath}.name`];

  const handleUnlinkContactClick = useCallback(() => {
    setContactFormShown(false);
    onChange({ name: "address", value: {} });
  }, [onChange]);

  const handleOpenContactFormClick = useCallback(() => {
    setContactFormShown(true);
  }, []);

  const handleContactFormCancelClick = useCallback(() => {
    setContactFormShown(false);
  }, []);

  const handleAddressNameChange = useCallback((value) => {
    onChange({ name: "address", value: { ...address, name: value } });
  }, [address, onChange]);

  const handleFormSubmit = useCallback(async({ state }) => {
    const { subType = paymentType === BUY ? VENDOR : CUSTOMER } = state;

    const contact = await dispatch(
      ContactsActions.addNewContact({
        ...state,
        name: address.name,
        subType
      })
    );

    if (!contact) return;

    setContactFormShown(false);

    onChange({ name: "address", value: contact });
    setContactAutocompleteOpened(false);
  }, [paymentType, address, dispatch, onChange, setContactAutocompleteOpened]);

  return (
    <>
      <div className={Css.documentTab}>
        <div className={classNames(Css.leftCol, contactFormShown && Css.contactFormShown)}>
          <div className={Css.content}>
            <div className={Css.row}>
              <div className={Css.col}>
                <div className={Css.label}>
                  <span>{uiTexts.type}</span>
                </div>
                <FormElement
                  name="documentType"
                  disabled={fetchingContacts}
                  readOnly={documentFrozen}
                  invalid={!type || !paymentType}
                  type={type}
                  paymentType={paymentType}
                  element={DocumentTypeSelect} />
              </div>
              <div className={Css.col}>
                <div className={Css.label}>
                  <span>{`${uiTexts.document} #`}</span>
                  <Confidence fieldsNames={["originalDocumentId"]} />
                </div>
                <FormElement
                  placeholder={uiTexts.enterDocumentId}
                  readOnly={documentFrozen}
                  name="originalDocumentId" />
              </div>
            </div>
            <div className={Css.row}>
              <div className={Css.col}>
                <div className={Css.label}>
                  <span>{uiTexts.issueDate}</span>
                  <Confidence fieldsNames={["issueDate"]} />
                </div>
                <FormElement
                  portal
                  name="issueDate"
                  placeholder={uiTexts.enterIssueDate}
                  readOnly={documentFrozen}
                  invalid={!issueDate || moment(issueDate).isAfter(new Date())}
                  element={DatePickerInput} />
              </div>
              <div className={Css.col}>
                {!receiptType && !salesReceiptType && (
                  <>
                    <div className={Css.label}>
                      <span>{uiTexts.dueDate}</span>
                      <Confidence fieldsNames={["dueDate"]} />
                    </div>
                    <FormElement
                      portal
                      name="dueDate"
                      placeholder={uiTexts.enterDueDate}
                      readOnly={documentFrozen}
                      invalid={dueDate && issueDate && moment(issueDate).isAfter(dueDate)}
                      element={DatePickerInput} />
                  </>
                )}
              </div>
            </div>
            <div className={Css.row}>
              <div className={Css.col}>
                <div className={Css.label}>
                  <span>{uiTexts.subtotal}</span>
                  <Confidence fieldsNames={["amountBase", "amountVat"]} />
                </div>
                <Input
                  readOnly
                  invalid={!lineItemsValid}
                  value={Utils.toMoneyString(+amountBase || 0)} />
              </div>
              <div className={Css.col}>
                <div className={Css.label}>
                  <span>{uiTexts.vat}</span>
                  <Confidence fieldsNames={["amountBase", "amountVat"]} />
                </div>
                <Input
                  readOnly
                  invalid={!lineItemsValid}
                  value={Utils.toMoneyString(+amountVat || 0)} />
              </div>
            </div>
            <div className={Css.row}>
              <div className={Css.col}>
                <div className={Css.label}>
                  <span>{uiTexts.total}</span>
                  <Confidence fieldsNames={["amountBase", "amountVat"]} />
                </div>
                <Input
                  readOnly
                  invalid={!lineItemsValid}
                  value={Utils.toMoneyString((+amountBase || 0) + (amountVat || 0))} />
              </div>
              <div className={Css.col}>
                <div className={Css.label}>
                  <span>{uiTexts.currency}</span>
                  <Confidence fieldsNames={["currency"]} />
                </div>
                <FormElement
                  valid={!!values.currency}
                  invalid={!values.currency}
                  name="currency"
                  readOnly={documentFrozen}
                  element={SelectCurrencyInput} />
              </div>
            </div>
            <div className={classNames(Css.addressWrap, contactFormShown && Css.contactFormShown)}>
              <div className={Css.label}>
                <span>{paymentType ? (buyPaymentType ? uiTexts.vendor : uiTexts.customer) : uiTexts.businessName}</span>
                {!contactFormShown
                && ((!documentData.vendorId && !vendorId) || documentData.vendorId === vendorId) && address.name && paymentType
                && (
                  <Confidence fieldsNames={addressConfidenceFieldsNames} useMax />
                )}
              </div>
              {contactFormShown
                ? (
                  <Input
                    readOnly={!!vendorId}
                    placeholder={uiTexts.enterContactName}
                    value={address.name}
                    onChange={handleAddressNameChange} />
                )
                : (
                  <div className={Css.flex}>
                    <FormElement
                      name="address"
                      className={Css.addressInput}
                      readOnly={documentFrozen || !allowClientPayeeSelection}
                      disabled={!paymentType || fetchingContacts}
                      opened={contactAutocompleteOpened}
                      probablyVendor={paymentType === BUY}
                      valid={!!(hasAddressName && vendorId)}
                      active={!documentFrozen && hasAddressName && !vendorId}
                      element={SelectContactInput}
                      setOpened={setContactAutocompleteOpened} />
                    {allowClientPayeeSelection && (
                      <Button
                        large
                        outline={!disabled}
                        className={Css.button}
                        disabled={disabled || documentFrozen
                          || !allowClientPayeeSelection || !paymentType || fetchingContacts}
                        primary={!disabled && !documentFrozen && !vendorId}
                        success={!disabled && !documentFrozen && !!vendorId}
                        onClick={handleOpenContactFormClick}>
                        {(documentFrozen || vendorId) ? <Icons.User /> : <Icons.UserPlus />}
                      </Button>
                    )}
                  </div>
                )}
            </div>
            {showLocations && (
              <div className={Css.row}>
                <div className={Css.col}>
                  <div className={Css.label}>{uiTexts.location}</div>
                  <FormElement
                    readOnly={documentFrozen}
                    invalid={!!(locationName && !locationId)}
                    valid={!!(locationName && locationId)}
                    name="location"
                    element={SelectLocationInput} />
                </div>
              </div>
            )}
          </div>
        </div>
        {contactFormShown && (
          <div className={Css.rightCol}>
            <ContactFormBlock
              documentFrozen={documentFrozen}
              onSubmit={handleFormSubmit}
              onUnlink={handleUnlinkContactClick}
              onCancel={handleContactFormCancelClick} />
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(DocumentTab);
