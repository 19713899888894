import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import Button from "nlib/ui/Button";
import React, { useCallback } from "react";
import classNames from "classnames";

export const DeleteCell = ({ id, disabled = false, className, onDelete }) => {
  const handleClick = useCallback(() => onDelete(id), [id, onDelete]);

  return (
    <div className={classNames(Css.deleteCell, className)}>
      <Button
        light
        disabled={disabled}
        className={Css.iconButton}
        onClick={handleClick}>
        <Icons.X />
      </Button>
    </div>
  );
};
