import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Select } from "nlib/ui";
import { getCurrentXeroOrganizationId, getSelectedBusinessItems } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import BrowserEvents from "const/BrowserEvents";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import SelectItemInput from "nlib/common/SelectItemInput";
import classNames from "classnames";
import useDocumentContext from "hooks/useDocumentContext";

const { DOCUMENT_TYPES: { SALES_RECEIPT } } = DataConstants;

const stopPropagation = (event) => event.stopPropagation();

const CATEGORY_AND_ITEM_SELECT_OPTIONS = {
  CATEGORY: "category",
  ITEM: "item"
};

const CATEGORY_AND_ITEM_FIELD_TYPES = [
  CATEGORY_AND_ITEM_SELECT_OPTIONS.CATEGORY,
  CATEGORY_AND_ITEM_SELECT_OPTIONS.ITEM
];

const CategoryOrItemCell = (props) => {
  const {
    uuid,
    item,
    category,
    className,
    onChange,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const items = useSelector(getSelectedBusinessItems);

  const {
    documentFrozen,
    documentState: { type },
    localState: { activeField },
    setLocalState
  } = useDocumentContext();

  const options = useMemo(() => {
    return [
      type !== SALES_RECEIPT
        && { value: CATEGORY_AND_ITEM_SELECT_OPTIONS.CATEGORY, label: uiTexts.category },
      !xeroBusiness && !!items.length && { value: CATEGORY_AND_ITEM_SELECT_OPTIONS.ITEM, label: uiTexts.item }
    ].filter(Boolean);
  }, [xeroBusiness, items.length, type, uiTexts]);

  const [mode, setMode] = useState(item ? CATEGORY_AND_ITEM_SELECT_OPTIONS.ITEM : options[0]?.value);

  const itemsMode = mode === CATEGORY_AND_ITEM_SELECT_OPTIONS.ITEM;

  const name = itemsMode ? "item" : "category";

  const fieldName = `${name}.${uuid}`;

  const active = activeField?.id === fieldName;

  const invalid = !documentFrozen && (itemsMode ? !item?.id : !category?.name);

  const value = itemsMode ? item : category;

  const displayValue = itemsMode ? item?.name : category?.displayName;

  const hasItemValue = !!item?.name;

  const handleEditClick = useCallback((event) => {
    event.stopPropagation();
    setLocalState({ activeField: { id: fieldName, selectable: true } });
  }, [fieldName, setLocalState]);

  const handleSaveClick = useCallback((event) => {
    if (event) event.stopPropagation();
    if (invalid) onChange(name);
    if (active) setLocalState({ activeField: null });
  }, [active, invalid, name, onChange, setLocalState]);

  const handleInputChange = useCallback((newValue) => {
    onChange(name, newValue);
  }, [name, onChange]);

  const handleClick = useCallback(() => {
    if (!documentFrozen) {
      setLocalState({ activeField: { id: fieldName, selectable: true } });
    }
  }, [documentFrozen, fieldName, setLocalState]);

  const handleModeChange = useCallback((val) => {
    setMode(val);
    setLocalState(({ activeField: prevActiveField }) => {
      if (prevActiveField?.id) {
        const [fieldType, prevUuid] = prevActiveField.id.split(".");

        if (prevUuid === uuid && CATEGORY_AND_ITEM_FIELD_TYPES.includes(fieldType)) {
          return {
            activeField: {
              id: `${val === CATEGORY_AND_ITEM_SELECT_OPTIONS.ITEM ? "item" : "category"}.${uuid}`
            }
          };
        }
      }

      return {};
    });
  }, [setLocalState, uuid]);

  const handleWindowKeyUp = useCallback(({ key }) => {
    if (key === Constants.KEY_NAMES.ENTER) {
      handleSaveClick();
    }
  }, [handleSaveClick]);

  useEffect(() => {
    if (active) {
      window.addEventListener(BrowserEvents.KEY_UP, handleWindowKeyUp);

      return () => {
        window.removeEventListener(BrowserEvents.KEY_UP, handleWindowKeyUp);
      };
    }

    return () => {};
  }, [active, handleWindowKeyUp]);

  useEffect(() => {
    if (xeroBusiness) return;

    if (options.length === 1) {
      setMode(options[0].value);
    } else if (item?.name) {
      setMode(CATEGORY_AND_ITEM_SELECT_OPTIONS.ITEM);
    } else if (category?.name) {
      setMode(CATEGORY_AND_ITEM_SELECT_OPTIONS.CATEGORY);
    }
  }, [xeroBusiness, hasItemValue, options, item?.name, category?.name]);

  const InputComponent = itemsMode ? SelectItemInput : SelectCategoryInput;

  return (
    <div
      className={classNames(Css.categoryOrItemCell, className, {
        [Css.editing]: active,
        [Css.invalid]: invalid,
        [Css.frozen]: documentFrozen
      })}
      onClick={handleClick}>
      <div className={Css.content}>
        {(options.length > 1) && (
          <Select
            portal
            disabled={documentFrozen}
            className={Css.select}
            dropDownClassName={Css.dropDownClassName}
            value={mode}
            options={options}
            onClick={stopPropagation}
            onChange={handleModeChange} />
        )}
        {!documentFrozen && active
          ? (
            <>
              <InputComponent
                portal
                autoFocus
                invalid={itemsMode
                  ? (!!value?.name && !value?.id)
                  : (!!value?.displayName && !value?.code)}
                valid={itemsMode
                  ? (!!value?.name && !!value?.id)
                  : (!!value?.displayName && !!value?.code)}
                {...restProps}
                value={value}
                className={Css.input}
                onChange={handleInputChange} />
              <div className={Css.icon} onClick={handleSaveClick}>
                <Icons.PencilSimple weight="bold" className={CommonCss.highlightText} />
              </div>
            </>
          )
          : (
            <>
              <div
                title={displayValue || undefined}
                className={classNames(Css.value, !displayValue && Css.placeholder)}>
                {invalid && <Icons.WarningCircle className={Css.iconInvalid} />}
                <div>
                  {displayValue || (itemsMode ? uiTexts.selectItem : uiTexts.selectCategory)}
                </div>
              </div>
              {!documentFrozen && (
                <div className={Css.icon} onClick={handleEditClick}>
                  <Icons.PencilSimple weight="bold" />
                </div>
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default React.memo(CategoryOrItemCell);
