import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getSelectedBusinessBookCloseDate, getSelectedBusinessIntegrationServiceData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ActionCellContent from "nlib/common/ActionCellContent";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentsActions from "actions/DocumentsActions";
import MainApiActions from "actions/MainApiActions";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const { STATUSES: { TO_EXTRACT } } = DataConstants;

const ACTIONS = {
  EDIT: "edit",
  DOWNLOAD: "download",
  DELETE: "delete"
};

const ActionsCell = (props) => {
  const {
    disableApprove,
    documentData,
    refetchDocuments,
    openDocument,
    onApprove
  } = props;

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const businessUser = useSelector(checkIsBusinessUser);

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const selectedBusinessIntegrationServiceData = useSelector(getSelectedBusinessIntegrationServiceData);

  const { uiTexts, messages } = useSelector(getTextsData);

  const { id: documentId, status, issueDate, attachment = {}, recogniseData = {} } = documentData;

  const toExtractStatus = status === TO_EXTRACT;

  const closedBookDate = selectedBusinessBookCloseDate && issueDate
    && moment.utc(issueDate).isSameOrBefore(moment.utc(selectedBusinessBookCloseDate));

  const menuItems = useMemo(() => {
    const warningApprove = !toExtractStatus && !disableApprove && closedBookDate;

    return [
      !businessUser && {
        theme: warningApprove ? "warning" : "success",
        text: uiTexts.approve,
        icon: warningApprove ? Icons.Warning : Icons.Check,
        action: "approve",
        disabled: toExtractStatus || disableApprove
      },
      {
        action: ACTIONS.EDIT,
        disabled: toExtractStatus
      },
      {
        action: ACTIONS.DOWNLOAD
      },
      {
        disabled: toExtractStatus && (recogniseData.status !== "error"),
        action: ACTIONS.DELETE
      }
    ].filter(Boolean);
  }, [businessUser, closedBookDate, uiTexts, toExtractStatus, disableApprove, recogniseData.status]);

  const handleMenuItemClick = useCallback(async(action) => {
    switch (action) {
      case "approve":
        if (closedBookDate) {
          showCommonModal({
            text: Utils.replaceTextVars(messages.closeBookWarningForDocs, {
              date: moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT),
              service: selectedBusinessIntegrationServiceData?.label
            }),
            headerText: uiTexts.warning
          });
        } else {
          onApprove(documentId);
        }
        break;
      case "edit":
        openDocument(documentId);
        break;
      case "download": {
        const link = await dispatch(MainApiActions.fetchAttachmentUrl(attachment, true));

        if (link) Utils.downloadContent(link);
        break;
      }
      case "delete": {
        const result = await showCommonModal({ text: messages.documentDeleteConfirm, confirm: true });

        if (result) {
          const successful = await dispatch(DocumentsActions.deleteDocument(documentId));

          if (successful) refetchDocuments();
        }
        break;
      }
      default:
        break;
    }
  }, [
    closedBookDate,
    openDocument,
    documentId,
    showCommonModal,
    messages.closeBookWarningForDocs,
    messages.documentDeleteConfirm,
    selectedBusinessBookCloseDate,
    selectedBusinessIntegrationServiceData?.label,
    uiTexts.warning,
    onApprove,
    dispatch,
    attachment,
    refetchDocuments
  ]);

  return (
    <div className={Css.actionsCell}>
      <ActionCellContent
        menuItems={menuItems}
        onClick={handleMenuItemClick} />
    </div>
  );
};

export default React.memo(ActionsCell);
