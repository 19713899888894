import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";

const { EMPTY_PLACEHOLDER } = Constants;

const {
  DOCUMENT_TYPES: { INVOICE, BILL, RECEIPT, SALES_RECEIPT },
  DOCUMENT_PAYMENT_TYPES: { BUY }
} = DataConstants;

const THEMES = {
  [INVOICE]: "positive",
  [SALES_RECEIPT]: "primary",
  [BILL]: "negative",
  [RECEIPT]: "alternative"
};

const TypeCell = ({ documentData: { type, paymentType } }) => {
  const { uiTexts } = useSelector(getTextsData);

  const billType = paymentType === BUY && type === INVOICE;

  const typeContent = (paymentType && type && (billType ? BILL : type));

  const textContent = uiTexts[typeContent] || uiTexts.unknown;

  return (
    <div className={Css.typeCell}>
      <div
        className={Css.documentType}
        title={textContent || undefined}>
        <Badge
          className={Css.badge}
          title={textContent || EMPTY_PLACEHOLDER}
          theme={THEMES[typeContent]}>
          {textContent || EMPTY_PLACEHOLDER}
        </Badge>
      </div>
    </div>
  );
};

export default React.memo(TypeCell);
