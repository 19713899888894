import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FieldContainer from "../FieldContainer";
import MoneyInput from "nlib/common/MoneyInput";
import React, { useCallback } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const AmountBaseField = (props) => {
  const id = "amountBase";

  const { uiTexts } = useSelector(getTextsData);

  const {
    lineItemsAmountGreaterThanDocument,
    lineItemsAmountLessThanDocument,
    documentState: { [id]: value, currency },
    setDocumentState
  } = useDocumentContext();

  const handleChange = useCallback((val) => {
    setDocumentState({ [id]: val });
  }, [setDocumentState]);

  return (
    <FieldContainer
      id={id}
      invalid={value < 0 || lineItemsAmountGreaterThanDocument || lineItemsAmountLessThanDocument}
      confidenceFields={["amountBase"]}
      label={uiTexts.subtotal}
      value={value}
      placeholder={uiTexts.enterSubtotal}
      displayValue={Utils.toMoneyString(+value || 0, currency)}
      component={MoneyInput}
      onChange={handleChange}
      {...props} />
  );
};

export default React.memo(AmountBaseField);
