import Css from "./style.module.scss";

import { Select } from "nlib/ui";
import { getCurrentXeroOrganizationId, getSelectedBusinessItems } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo, useState } from "react";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import SelectItemInput from "nlib/common/SelectItemInput";

const { DOCUMENT_TYPES: { SALES_RECEIPT } } = DataConstants;

const CATEGORY_AND_ITEM_SELECT_OPTIONS = {
  CATEGORY: "category",
  ITEM: "item"
};

const preventPropagation = (event) => event.stopPropagation();

const CategoryAndItemsSelect = (props) => {
  const {
    type,
    paymentType,
    onModeChange,
    item,
    category,
    onChange,
    ...rest
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const items = useSelector(getSelectedBusinessItems);

  const options = useMemo(() => {
    return [
      type !== SALES_RECEIPT
        && { value: CATEGORY_AND_ITEM_SELECT_OPTIONS.CATEGORY, label: uiTexts.category },
      !xeroBusiness && !!items.length && { value: CATEGORY_AND_ITEM_SELECT_OPTIONS.ITEM, label: uiTexts.item }
    ].filter(Boolean);
  }, [xeroBusiness, items.length, type, uiTexts]);

  const [mode, setMode] = useState(options[0]?.value);

  const selectItemMode = mode === CATEGORY_AND_ITEM_SELECT_OPTIONS.ITEM;

  const InputComponent = selectItemMode ? SelectItemInput : SelectCategoryInput;

  const handleChange = useCallback((val) => {
    if (onChange) onChange(mode, val);
  }, [onChange, mode]);

  const handleModeChange = useCallback((val) => {
    setMode(val);
    onChange(val);
  }, [onChange]);

  return (
    <div className={Css.container}>
      {(options.length > 1) && (
        <Select
          portal
          modal={false}
          className={Css.selectCategoryOrItem}
          dropDownClassName={Css.dropDownClassName}
          value={mode}
          options={options}
          onClick={preventPropagation}
          onChange={handleModeChange} />
      )}
      <InputComponent
        {...rest}
        value={selectItemMode ? item : category}
        onChange={handleChange} />
    </div>
  );
};

export { CATEGORY_AND_ITEM_SELECT_OPTIONS };

export default React.memo(CategoryAndItemsSelect);
