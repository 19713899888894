import { Input } from "nlib/ui";
import React, { useCallback } from "react";
import Utils from "utils/Utils";

const MoneyInput = ({ value, onChange, ...props }) => {
  const handleChange = useCallback((newValue) => {
    onChange(newValue && Utils.toMoneyNumber(newValue || 0));
  }, [onChange]);

  return (
    <Input
      {...props}
      type="number"
      step="any"
      value={value}
      onChange={handleChange} />
  );
};

export default React.memo(MoneyInput);
