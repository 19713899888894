import Css from "./style.module.scss";

import Activity from "nlib/common/Activity";
import DataConstants from "const/DataConstants";
import React from "react";
import useDocumentContext from "hooks/useDocumentContext";

const { COMMENT_TARGET_TYPES: { DOCUMENTS } } = DataConstants;

const ActivityTab = () => {
  const { documentData } = useDocumentContext();

  return (
    <div className={Css.activityTab}>
      <Activity
        showAllInitial
        itemId={documentData.id}
        type={DOCUMENTS}
        commentsCount={documentData.comments} />
    </div>
  );
};

export default React.memo(ActivityTab);
