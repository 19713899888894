import Css from "./style.module.scss";

import { toMinMax } from "nlib/common/EditDocumentContent/utils";
import React, { useMemo } from "react";

const DragRectangle = ({ dragCoords, bgColor }) => {
  const minMax = toMinMax(dragCoords);

  const style = useMemo(() => {
    return {
      backgroundColor: bgColor ? `${bgColor}1a` : "transparent",
      border: `1px solid ${bgColor || "#386df4"}`,
      top: `${minMax.minY}px`,
      left: `${minMax.minX}px`,
      width: `${minMax.maxX - minMax.minX}px`,
      height: `${minMax.maxY - minMax.minY}px`
    };
  }, [bgColor, minMax.maxX, minMax.maxY, minMax.minX, minMax.minY]);

  return (
    <div style={style} className={Css.dragRectangle} />
  );
};

export default DragRectangle;
