import Css from "./style.module.scss";

import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import ConfidencePercents from "nlib/common/ConfidencePercents";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "nlib/pages/DocumentsPage/DocumentsStatuses";
import React, { useMemo } from "react";
import classNames from "classnames";

const { STATUSES: { TO_REVIEW, TO_EXTRACT } } = DataConstants;

const Content = ({ errorState, duplicatedDocument, toReviewStatus, recogniseData }) => {
  const { uiTexts } = useSelector(getTextsData);

  if (errorState) {
    return (
      <Badge negative className={Css.badge} value={uiTexts.error} />
    );
  }

  if (duplicatedDocument) {
    return (
      <Badge warning className={Css.badge} value={uiTexts.duplicate} />
    );
  }

  if (toReviewStatus && !recogniseData.confidence) {
    return (
      <Badge primary className={Css.badge} value={uiTexts.edited} />
    );
  }

  if (recogniseData.confidence) {
    return (
      <ConfidencePercents
        className={classNames(Css.badge, Css.confidence)}
        value={recogniseData.averageConfidence}
        component={Badge} />
    );
  }

  return null;
};

const StatusCell = ({ documentData }) => {
  const { status, recogniseData = {}, duplicatedDocumentsIds } = documentData;

  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const { labelLangId, iconComponent: Icon, errorIconComponent: ErrorIcon } = useMemo(() => {
    return DocumentsStatuses.getStatusData(
      status,
      activeOrganization.countryCode
    );
  }, [status, activeOrganization]);

  const toReviewStatus = status === TO_REVIEW;

  const errorState = status === TO_EXTRACT && recogniseData.status === "error";

  const duplicatedDocument = Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length;

  const withBadge = errorState || duplicatedDocument || (toReviewStatus && !recogniseData.confidence) || recogniseData.confidence;

  return (
    <div
      title={uiTexts[labelLangId]}
      className={classNames(
        Css.statusCell,
        Css[errorState ? recogniseData.status : status],
        withBadge && Css.withBadge
      )}>
      {errorState ? <ErrorIcon /> : <Icon />}
      <Content
        errorState={errorState}
        duplicatedDocument={duplicatedDocument}
        toReviewStatus={toReviewStatus}
        recogniseData={recogniseData} />
    </div>
  );
};

export default React.memo(StatusCell);
