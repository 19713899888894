import * as Icons from "@phosphor-icons/react";
import { Select } from "nlib/ui";
import { getAccountsData } from "selectors/accounts";
import { getSelectedBusinessCategories } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";

const SelectPaidWith = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const accountsData = useSelector(getAccountsData);

  const categories = useSelector(getSelectedBusinessCategories);

  const groups = useMemo(() => {
    return [
      { value: "1", label: uiTexts.bankAccounts },
      { value: "2", label: uiTexts.otherAccounts }
    ];
  }, [uiTexts]);

  const options = useMemo(() => {
    return [
      ...(accountsData.map(({ name, extraData: { xeroAccountId, zohoAccountId } = {} }) => {
        return { value: xeroAccountId || zohoAccountId, label: name, group: "1" };
      })),
      ...(categories.filter(({ paymentsAllowed }) => paymentsAllowed).map(({ accountId, displayName }) => {
        return { value: accountId, label: displayName, group: "2" };
      }))
    ].filter(Boolean);
  }, [accountsData, categories]);

  return (
    <Select
      groups={groups}
      options={options}
      iconBefore={Icons.Bank}
      {...props} />
  );
};

export default React.memo(SelectPaidWith);
