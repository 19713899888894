import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FieldContainer from "../FieldContainer";
import MoneyInput from "nlib/common/MoneyInput";
import React, { useCallback } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const AmountVatField = (props) => {
  const id = "amountVat";

  const { uiTexts } = useSelector(getTextsData);

  const {
    lineItemsAmountVatGreaterThanDocument,
    lineItemsAmountVatLessThanDocument,
    documentState: { [id]: value, currency, lineItems },
    setDocumentState
  } = useDocumentContext();

  const editable = lineItems.every(({ taxRate }) => !taxRate?.id || taxRate?.rateDetails);

  const invalid = lineItemsAmountVatGreaterThanDocument || lineItemsAmountVatLessThanDocument || value < 0;

  const handleChange = useCallback((val) => {
    setDocumentState({ [id]: val });
  }, [setDocumentState]);

  return (
    <FieldContainer
      type="number"
      step="any"
      id={id}
      invalid={invalid}
      confidenceFields={["amountVat"]}
      label={uiTexts.vat}
      value={value}
      placeholder={uiTexts.enterVat}
      displayValue={Utils.toMoneyString(+value || 0, currency)}
      component={MoneyInput}
      onChange={editable ? handleChange : undefined}
      {...props} />
  );
};

export default React.memo(AmountVatField);
