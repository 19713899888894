import { Select } from "nlib/ui";
import { getAccountsData } from "selectors/accounts";
import { getSelectedBusinessCategories } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import FieldContainer from "../FieldContainer";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const IssueDateField = (props) => {
  const id = "payTo";

  const { uiTexts } = useSelector(getTextsData);

  const accountsData = useSelector(getAccountsData);

  const categories = useSelector(getSelectedBusinessCategories);

  const {
    documentState: {
      [id]: value,
      paymentType,
      paid
    },
    setDocumentState
  } = useDocumentContext();

  const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

  const groups = useMemo(() => {
    return [
      { value: "a", label: uiTexts.bankAccounts },
      { value: "b", label: uiTexts.otherAccounts }
    ];
  }, [uiTexts]);

  const options = useMemo(() => {
    return [
      ...accountsData.map(({ name, extraData = {} }) => {
        const { xeroAccountId, zohoAccountId, quickBooksAccountId, paymentAccount } = extraData;

        if (quickBooksAccountId && !paymentAccount) return null;

        return { group: "a", value: xeroAccountId || zohoAccountId || quickBooksAccountId, label: name };
      }).filter(Boolean),
      ...categories
        .map(({ accountId, displayName, paymentsAllowed }) => {
          return paymentsAllowed && { group: "b", value: accountId, label: displayName };
        }).filter(Boolean)
    ];
  }, [accountsData, categories]);

  const handleChange = useCallback((newValue) => {
    setDocumentState({
      [id]: { serviceAccountId: newValue }
    });
  }, [setDocumentState]);

  return (
    <FieldContainer
      id={id}
      selectable={false}
      confidenceFields={["payTo"]}
      label={buyPaymentType ? uiTexts.paidFrom : uiTexts.paidTo}
      placeholder={uiTexts.selectPaymentAccount}
      invalid={paid && !value?.serviceAccountId}
      groups={groups}
      options={options}
      displayValue={Utils.arrayFind(options, "value", value?.serviceAccountId)?.label}
      value={value?.serviceAccountId}
      component={Select}
      onChange={handleChange}
      {...props} />
  );
};

export default React.memo(IssueDateField);
