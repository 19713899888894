import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { getUploadingDocumentsCount } from "selectors/documents";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import React, { useCallback, useState } from "react";
import UploadDocumentsWindow from "nlib/windows/UploadDocumentsWindow";
import useUploadDocuments from "hooks/useUploadDocuments";

const HeaderButtons = ({ disabled, refetchDocuments }) => {
  const { uiTexts } = useSelector(getTextsData);

  const documentsCurrentUploadCount = useSelector(getUploadingDocumentsCount);

  const [uploadDocumentsWindowOpened, setUploadDocumentsWindowOpened] = useState(false);

  const uploadDocuments = useUploadDocuments();

  const handleUploadDocumentsButtonClick = useCallback(() => {
    setUploadDocumentsWindowOpened(true);
  }, []);

  const handleUploadDocumentsWindowClose = useCallback(async(result) => {
    setUploadDocumentsWindowOpened(false);

    if (!result) return;

    const response = await uploadDocuments(result);

    if (response) refetchDocuments();
  }, [uploadDocuments, refetchDocuments]);

  return (
    <>
      <div className={Css.headerButtons}>
        <Button
          large primary
          data-loading={documentsCurrentUploadCount || undefined}
          disabled={disabled || !!documentsCurrentUploadCount}
          icon={documentsCurrentUploadCount ? Icons.Spinner : Icons.CloudArrowUp}
          onClick={handleUploadDocumentsButtonClick}>
          {uiTexts.uploadDocuments}
        </Button>
      </div>
      {uploadDocumentsWindowOpened && (
        <UploadDocumentsWindow onClose={handleUploadDocumentsWindowClose} />
      )}
    </>
  );
};

export default React.memo(HeaderButtons);
