export const ColoredBlocks = [
  "documentType",
  "originalDocumentId",
  "currency",
  "amountBase",
  "amountVat",
  "amount",
  "issueDate",
  "dueDate",
  "address"
];

export const ColorSet = [
  "#386df4",
  "#36b37e",
  "#00c7e6",
  "#491fe0",
  "#ffab00",
  "#9c27b0",
  "#c878e6"
];

export const FieldColors = ColoredBlocks
  .reduce((result, field, index) => ({ ...result, [field]: ColorSet[index % ColorSet.length] }), {});
