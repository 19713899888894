import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge } from "nlib/ui";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback } from "react";

const { STATUSES: { TO_EXTRACT } } = DataConstants;

const TransactionsCell = ({ documentData, onPairTransactionsLinkClick }) => {
  const { id, status, matchedTransactions = 0, paidTransactions = [] } = documentData;

  const extractStatus = status === TO_EXTRACT;

  const pairedTransactionsCount = paidTransactions.length;

  const handlePairTransactionsLinkClick = useCallback(() => {
    onPairTransactionsLinkClick(id);
  }, [id, onPairTransactionsLinkClick]);

  if (extractStatus) return Constants.EMPTY_PLACEHOLDER;

  return (
    <a
      title={pairedTransactionsCount}
      className={Css.transactionsCell}
      onClick={handlePairTransactionsLinkClick}>
      <Icons.CreditCard />
      <Badge
        counter inverted
        theme={pairedTransactionsCount ? "primary" : (matchedTransactions ? "alternative" : "")}>
        {pairedTransactionsCount || (matchedTransactions && `${matchedTransactions}?`)}
      </Badge>
    </a>
  );
};

export default React.memo(TransactionsCell);
