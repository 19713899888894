import Css from "./style.module.scss";

import { Input } from "nlib/ui";
import {
  getCurrentXeroOrganizationId,
  getSelectedBusinessItems
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectCategoryOrItem from "./lib/SelectCategoryOrItem";

const { DOCUMENT_TYPES: { SALES_RECEIPT }, DOCUMENT_PAYMENT_TYPES } = DataConstants;

const stopPropagation = (event) => event.stopPropagation();

const InteractiveField = ({ documentId, type, paymentType, lineItems, onEdit }) => {
  const { uiTexts } = useSelector(getTextsData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const items = useSelector(getSelectedBusinessItems);

  const [value, setValue] = useState({});

  const filteredItems = useMemo(() => {
    if (!xeroBusiness) return items;

    return items.filter(({ salesItem, purchaseItem }) => {
      return !paymentType || (paymentType === DOCUMENT_PAYMENT_TYPES.BUY ? purchaseItem : salesItem);
    });
  }, [xeroBusiness, paymentType, items]);

  const handleChange = useCallback((newValue) => {
    setValue(newValue);
    if (newValue?.code || newValue?.id) {
      const [lineItem = {}] = lineItems || [];

      if (newValue.code && lineItem.category?.code === newValue.code) return;
      if (newValue.id && lineItem.item?.id === newValue.id) return;

      onEdit(documentId, {
        lineItems: [{
          ...lineItem,
          category: newValue.code ? newValue : undefined,
          item: newValue.id ? newValue : undefined
        }]
      });
    }
  }, [documentId, lineItems, onEdit]);

  useEffect(() => {
    const [{ category, item } = {}] = lineItems || [];

    if (category?.code || item?.id) {
      setValue((category?.code && category) || (item?.id && item) || { name: "" });
    } else {
      setValue({ name: "" });
    }
  }, [lineItems]);

  if (lineItems.length > 1) {
    return (
      <Input className={Css.split} readOnly value={`(${uiTexts.split})`} />
    );
  }

  if (type === SALES_RECEIPT && !filteredItems.length) {
    return Constants.EMPTY_PLACEHOLDER;
  }

  return (
    <div onClick={stopPropagation}>
      <SelectCategoryOrItem
        type={type}
        paymentType={paymentType}
        value={value}
        onChange={handleChange} />
    </div>
  );
};

export default React.memo(InteractiveField);
