import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback, useEffect, useState } from "react";

const { PERCENTS_MULTIPLIER } = Constants;

const MULTIPLIER = 0.2;

const ROUNDER = 20;

const SCALE_PAGE_FIT = "pageFit";

const SCALE_PAGE_WIDTH = "pageWidth";

const SCALE_A = 0.5;

const SCALE_B = 0.75;

const SCALE_C = 1;

const SCALE_D = 1.25;

const SCALE_E = 1.5;

const SCALE_F = 2;

const SCALE_G = 3;

const SCALE_H = 4;

const MIN_SCALE = 0.1;

const MAX_SCALE = 9.99;

const SIZES = [
  SCALE_PAGE_FIT,
  SCALE_PAGE_WIDTH,
  SCALE_A,
  SCALE_B,
  SCALE_C,
  SCALE_D,
  SCALE_E,
  SCALE_F,
  SCALE_G,
  SCALE_H
];

const DocumentControlPanel = (props) => {
  const {
    disabled,
    pageWidthScale,
    pagesCount,
    currentPage,
    documentScale,
    setDocumentScale,
    onPageChange,
    onRequestFullSize
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const [pageInputValue, setPageInputValue] = useState(currentPage);

  const scaleValue = documentScale || pageWidthScale;

  const handleSelectChange = useCallback(({ target: { value } }) => {
    setDocumentScale([SCALE_PAGE_FIT, SCALE_PAGE_WIDTH].includes(value) ? value : parseFloat(value));
  }, [setDocumentScale]);

  const handleScaleMinusClick = useCallback(() => {
    const nextScale = Math.round(
      (scaleValue - (scaleValue * MULTIPLIER)) * ROUNDER
    ) / ROUNDER;

    setDocumentScale(Math.max(MIN_SCALE, nextScale));
  }, [scaleValue, setDocumentScale]);

  const handleScalePlusClick = useCallback(() => {
    const nextScale = Math.ceil(
      (scaleValue + (scaleValue * MULTIPLIER)) * ROUNDER
    ) / ROUNDER;

    setDocumentScale(Math.min(MAX_SCALE, nextScale));
  }, [scaleValue, setDocumentScale]);

  const handlePrevPageClick = useCallback(() => {
    onPageChange(Math.max(1, currentPage - 1));
  }, [currentPage, onPageChange]);

  const handleNextPageClick = useCallback(() => {
    onPageChange(Math.min(pagesCount, currentPage + 1));
  }, [currentPage, onPageChange, pagesCount]);

  const handlePageInputChange = useCallback(({ target: { value } }) => {
    setPageInputValue(value);

    const page = parseInt(value, 10);

    if (page && page > 0 && page <= pagesCount) onPageChange(page);
  }, [onPageChange, pagesCount]);

  const handlePageInputBlur = useCallback(({ target: { value } }) => {
    const page = parseInt(value, 10);

    if (!page || page < 0 || page > pagesCount) setPageInputValue(currentPage);
  }, [currentPage, pagesCount]);

  const handleFullSizeClick = useCallback(() => {
    onRequestFullSize();
  }, [onRequestFullSize]);

  useEffect(() => {
    setPageInputValue(currentPage);
  }, [currentPage]);

  return (
    <div className={Css.documentControlPanel} disabled={disabled}>
      <div className={Css.left}>
        <Button light disabled={currentPage === 1} onClick={handlePrevPageClick}>
          <Icons.CaretUp />
        </Button>
        <input
          value={pageInputValue}
          onChange={handlePageInputChange}
          onBlur={handlePageInputBlur} /> / {pagesCount}
        <Button light disabled={currentPage === pagesCount} onClick={handleNextPageClick}>
          <Icons.CaretDown />
        </Button>
      </div>
      <div className={Css.center}>
        <Button
          light
          disabled={documentScale === MIN_SCALE}
          onClick={handleScaleMinusClick}>
          <Icons.MagnifyingGlassMinus />
        </Button>
        <select
          value={scaleValue}
          className={Css.select}
          onChange={handleSelectChange}>
          {!SIZES.includes(scaleValue) && <option value={scaleValue}>
            {`${(scaleValue * PERCENTS_MULTIPLIER).toFixed(0)}%`}
          </option>}
          {SIZES.map((size) => {
            return <option key={size} value={size}>{uiTexts[size] || `${size * PERCENTS_MULTIPLIER}%`}</option>;
          })}
        </select>
        <Button
          light
          disabled={documentScale === MAX_SCALE}
          onClick={handleScalePlusClick}>
          <Icons.MagnifyingGlassPlus />
        </Button>
      </div>
      <div className={Css.right}>
        <Button light onClick={handleFullSizeClick}>
          <Icons.ArrowsOut />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(DocumentControlPanel);
