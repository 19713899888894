import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import AutoTooltip from "nlib/ui/AutoTooltip";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";
import Utils from "utils/Utils";

const { EMPTY_PLACEHOLDER } = Constants;

const {
  STATUSES: { TO_EXTRACT },
  DOCUMENT_PAYMENT_TYPES: { BUY }
} = DataConstants;

const AmountCell = ({ documentData }) => {
  const { status, paymentType, amountBase, amountVat, currency } = documentData;

  const value = (+amountBase || 0) + (+amountVat || 0);

  const buyPayment = paymentType === BUY;

  if (status === TO_EXTRACT) return EMPTY_PLACEHOLDER;

  const content = Utils.toMoneyString(value, currency);

  return (
    <div className={Css.amountCell}>
      <AutoTooltip
        className={(paymentType && (value || value === 0))
          ? CommonCss.coloredNumber
          : (paymentType ? "" : CommonCss.mediumDarkText)}
        data-negative={buyPayment ? "" : undefined}
        active={!!value}>
        {content}
      </AutoTooltip>
    </div>
  );
};

export default React.memo(AmountCell);
