import ActionCellContent from "nlib/common/ActionCellContent";
import React, { useCallback, useMemo } from "react";

const Component = ({ id, onPreview, onDelete }) => {
  const menuItems = useMemo(() => {
    return [
      { action: "preview" },
      !!onDelete && { action: "delete" }
    ].filter(Boolean);
  }, [onDelete]);

  const handleActionClick = useCallback((action) => {
    switch (action) {
      case "preview": onPreview(id);
        break;

      case "delete": onDelete(id);
        break;

      default:
        break;
    }
  }, [id, onDelete, onPreview]);

  return (
    <ActionCellContent
      menuItems={menuItems}
      onClick={handleActionClick} />
  );
};

export default React.memo(Component);
