import * as Icons from "@phosphor-icons/react";
import { getCurrenciesData } from "selectors/metaData";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoCompleteInput from "nlib/ui/AutoCompleteInput";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const SelectCurrencyInput = (props) => {
  const {
    value = "",
    placeholder,
    onChange,
    ...restProps
  } = props;

  const [opened, setOpened] = useState(false);

  const { uiTexts } = useSelector(getTextsData);

  const [showAll, setShowAll] = useState(false);

  const currenciesData = useSelector(getCurrenciesData);

  const currencies = useMemo(() => {
    return currenciesData.map(({ code }) => code);
  }, [currenciesData]);

  const [text, setText] = useState("");

  const trimmedText = text.trim().toUpperCase();

  const items = useMemo(() => {
    return currencies.sort().map((code) => ({ value: code, label: code }));
  }, [currencies]);

  const filteredItems = useMemo(() => {
    if (showAll) return items;
    if (currencies.includes(value)) return [];

    return items.filter((item) => {
      return item.label === trimmedText || item.label.includes(trimmedText);
    });
  }, [showAll, items, currencies, value, trimmedText]);

  const handleChange = useCallback((newValue) => {
    const val = newValue.trim().toUpperCase();

    if (currencies.includes(val)) {
      setText(val);
      onChange(val);
    } else {
      setText(newValue);
      onChange("");
    }
  }, [currencies, onChange]);

  const handleAutoComplete = useCallback((item, event) => {
    setText(item.label);
    onChange(item.value, event);
  }, [onChange]);

  useEffect(() => {
    if (currencies.includes(value)) {
      setText(value);
    }
  }, [currencies, setText, value]);

  return (
    <AutoCompleteInput
      {...restProps}
      title={uiTexts.currency}
      iconBefore={Icons.Money}
      value={text}
      showAll={showAll}
      placeholder={placeholder || uiTexts.selectCurrency}
      items={items}
      filteredItems={filteredItems}
      opened={opened}
      setOpened={setOpened}
      onSetShowAll={setShowAll}
      onChange={handleChange}
      onAutoComplete={handleAutoComplete} />
  );
};

export default React.memo(SelectCurrencyInput);
