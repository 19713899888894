import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DragAndDropArea from "nlib/ui/DragAndDropArea";
import React, { useMemo } from "react";
import Utils from "utils/Utils";

const DropArea = (props) => {
  const { extensions = [] } = props;

  const { uiTexts, messages } = useSelector(getTextsData);

  const acceptedExtensions = useMemo(() => {
    return extensions.map((extension) => extension.substr(1).toUpperCase()).join(", ");
  }, [extensions]);

  return (
    <DragAndDropArea {...props}>
      {({ accepted }) => (
        <div className={Css.dropArea}>
          {accepted.length
            ? (
              <div className={Css.accepted}>
                <Icons.FilePlus />
                {(accepted.length > 1) ? `${uiTexts.filesSelected}: ${accepted.length}` : accepted[0].name}
              </div>
            )
            : (
              <>
                <div className={Css.text}>
                  <Icons.FileText />
                  <span>{Utils.checkIsTouchDevice() ? messages.fileUploadDescription : messages.fileDropDescription}</span>
                </div>
                <span className={Css.formats}>
                  <span>{uiTexts.supportedFormats}:</span>
                  <div className={Css.extensions}>
                    {extensions.length ? acceptedExtensions : uiTexts.any.toLowerCase()}
                  </div>
                </span>
              </>
            )}
        </div>
      )}
    </DragAndDropArea>
  );
};

export default React.memo(DropArea);
