import Css from "./style.module.scss";

import React from "react";
import SimpleBlock from "./lib/SimpleBlock";
import useDocumentContext from "hooks/useDocumentContext";

const AllAvailableBlocks = ({ width, docPageHeights, wordBlocks }) => {
  const { localState: { activeField } } = useDocumentContext();

  return (
    <div className={Css.allAvailableBlocks}>
      {!!activeField && activeField.selectable && wordBlocks.map((block) => (
        <SimpleBlock
          key={block.id}
          block={block}
          docPageHeights={docPageHeights}
          docRenderWidth={width} />
      ))}
    </div>
  );
};

export default React.memo(AllAvailableBlocks);
