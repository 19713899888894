import Css from "./style.module.scss";

import Content from "./Content";
import Provider from "./Provider";
import React, { useRef } from "react";

const EditDocumentContent = (props) => {
  const {
    disabled,
    recogniseData,
    documentData,
    documentFrozen,
    state,
    onChange,
    onPairedTransactionClick
  } = props;

  const { attachment } = documentData || {};

  const rootRef = useRef(null);

  return (
    <div className={Css.container}>
      <div ref={rootRef} className={Css.documentLabeler}>
        <Provider
          rootRef={rootRef}
          state={state}
          disabled={disabled}
          attachment={attachment}
          documentData={documentData}
          recogniseData={recogniseData}
          documentFrozen={documentFrozen}
          onChange={onChange}
          onPairedTransactionClick={onPairedTransactionClick} >
          <Content disabled={disabled} />
        </Provider>
      </div>
    </div>
  );
};

export default React.memo(EditDocumentContent);
