import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { ContainerRefContext } from "./lib/Fields/lib/FieldContainer";
import { Preloader, Tab, Tabs } from "nlib/ui";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ActivityTab from "./lib/ActivityTab";
import Fields from "./lib/Fields";
import IntegrationServices from "const/IntegrationServices";
import MainApiActions from "actions/MainApiActions";
import React, { useCallback, useRef, useState } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";
import useEnvVars from "hooks/useEnvVars";

const MODES = {
  DEFAULT: "default",
  ACTIVITY: "comments"
};

const MODES_LIST = Object.values(MODES);

export const FieldsPanel = ({ disabled }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const containerRef = useRef();

  const [{ editItem }, setEnvVars] = useEnvVars();

  const [editDocumentId, additional] = editItem ? editItem.split(".") : [];

  const currentTab = MODES_LIST.includes(additional) ? additional : MODES.DEFAULT;

  const { extraData: { integrationService } } = useSelector(getSelectedBusinessData);

  const {
    attachment,
    documentData: {
      extraData: { xeroSourceUrl, zohoSourceUrl } = {}
    }
  } = useDocumentContext();

  const [fetched, setFetched] = useState(false);

  const handleDownloadLinkClick = useCallback(async(event) => {
    event.preventDefault();
    setFetched(true);
    await dispatch(MainApiActions.fetchAttachmentUrl(attachment, true))
      .then((link) => link && Utils.downloadContent(link));
    setFetched(false);
  }, [attachment, dispatch]);

  const handleTabsChange = useCallback((tab) => {
    setEnvVars({ editItem: tab === MODES.DEFAULT ? editDocumentId : `${editDocumentId}.${tab}` });
  }, [editDocumentId, setEnvVars]);

  const handleExternalLinkClick = useCallback(() => {
    window.open(xeroSourceUrl || zohoSourceUrl, "_blank");
  }, [xeroSourceUrl, zohoSourceUrl]);

  return (
    <ContainerRefContext.Provider value={containerRef}>
      <div className={Css.fieldsPanel}>
        <div className={Css.header}>
          <div className={Css.links}>
            <div className={Css.downloadLink} title={attachment?.originalName || uiTexts.download}>
              <a disabled={!attachment?.key || fetched} onClick={handleDownloadLinkClick}>
                <Icons.DownloadSimple weight="bold" />
                <span>{attachment?.originalName || uiTexts.download}</span>
              </a>
            </div>
            {!!(xeroSourceUrl || zohoSourceUrl) && (
              <div className={Css.externalLinkContainer} onClick={handleExternalLinkClick}>
                <a>
                  <Icons.ArrowSquareOut weight="bold" />
                  <span>
                    {Utils.replaceTextVars(
                      uiTexts.openInService,
                      { service: IntegrationServices.getByValue(integrationService).label }
                    )}
                  </span>
                </a>
              </div>
            )}
          </div>
          <Tabs className={Css.tabs} current={currentTab} onChange={handleTabsChange}>
            <Tab className={Css.tab} value={MODES.DEFAULT}>
              <Icons.NotePencil /><span>{uiTexts.document}</span>
            </Tab>
            <Tab className={Css.tab} value={MODES.ACTIVITY}>
              <Icons.Lightning /><span>{uiTexts.activity}</span>
            </Tab>
          </Tabs>
        </div>
        <div className={Css.fieldsContainer} ref={containerRef}>
          {({
            [MODES.DEFAULT]: <Fields />,
            [MODES.ACTIVITY]: <ActivityTab />
          }[currentTab])}
        </div>
        {disabled && <Preloader overlayOnly absolute />}
      </div>
    </ContainerRefContext.Provider>
  );
};
