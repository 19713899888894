import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import FieldContainer from "../FieldContainer";
import React, { useMemo } from "react";
import moment from "moment";
import useDocumentContext from "hooks/useDocumentContext";

const { DATETIME_FORMATS: { DATE_TEXT } } = Constants;

const PairedTransactionsField = (props) => {
  const id = "pairedTransactions";

  const { uiTexts } = useSelector(getTextsData);

  const {
    documentData: { paidTransactions = [], matchedTransactions = 0 },
    onPairedTransactionClick
  } = useDocumentContext();

  const fieldValue = useMemo(() => {
    if (paidTransactions.length) {
      return paidTransactions.map((transaction) => {
        const { timestamp, amount, currency, extra = {}, description = "" } = transaction;

        return [moment(timestamp).format(DATE_TEXT),
          `${amount} ${currency}`,
          transaction.address && transaction.address.name,
          extra.VS && `VS ${extra.VS}`,
          extra.KS && `KS ${extra.KS}`,
          extra.SS && `SS ${extra.SS}`,
          description].filter(Boolean).join(", ");
      }).join("; ");
    }

    if (matchedTransactions) {
      return `${uiTexts.matching}: ${matchedTransactions}`;
    }

    return null;
  }, [matchedTransactions, paidTransactions, uiTexts]);

  return (
    <FieldContainer
      id={id}
      label={uiTexts.pairedTransactions}
      displayValue={fieldValue || uiTexts.noTransactionsFound}
      onClick={onPairedTransactionClick}
      {...props} />
  );
};

export default React.memo(PairedTransactionsField);
