import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FieldContainer from "../FieldContainer";
import React, { useCallback } from "react";
import useDocumentContext from "hooks/useDocumentContext";

const DocumentIdField = (props) => {
  const id = "originalDocumentId";

  const { uiTexts } = useSelector(getTextsData);

  const {
    documentState: { [id]: value, paid },
    setDocumentState
  } = useDocumentContext();

  const handleChange = useCallback((newValue) => {
    setDocumentState({ [id]: newValue });
  }, [setDocumentState]);

  return (
    <FieldContainer
      id={id}
      confidenceFields={["originalDocumentId"]}
      label={`${uiTexts.document} #`}
      placeholder={uiTexts.enterDocumentId}
      invalid={paid && !value}
      value={value}
      displayValue={value}
      onChange={handleChange}
      {...props} />
  );
};

export default React.memo(DocumentIdField);
