import { checkIsBusinessUser } from "selectors/user";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DatePickerInput from "nlib/common/DatePickerInput";
import FieldContainer from "../FieldContainer";
import React, { useCallback } from "react";
import moment from "moment";
import useDateFormatter from "hooks/useDateFormatter";
import useDocumentContext from "hooks/useDocumentContext";

const { DEFAULT_DOCUMENT_DUE_DATE_DAYS_OFFSET } = Constants;

const DueDateField = (props) => {
  const id = "dueDate";

  const dateFormatter = useDateFormatter();

  const { uiTexts } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const {
    documentState: { [id]: value, issueDate },
    setDocumentState
  } = useDocumentContext();

  const handleChange = useCallback((newValue) => {
    setDocumentState({ [id]: newValue });
  }, [setDocumentState]);

  return (
    <FieldContainer
      portal
      id={id}
      confidenceFields={["dueDate"]}
      label={uiTexts.dueDate}
      placeholder={uiTexts.enterDueDate}
      invalid={!businessUser && value && issueDate && moment.utc(issueDate).isAfter(moment.utc(value))}
      openToDate={!value && issueDate && moment.utc(issueDate).add(DEFAULT_DOCUMENT_DUE_DATE_DAYS_OFFSET, "days")}
      value={value}
      displayValue={value ? dateFormatter(value) : null}
      onChange={handleChange}
      component={DatePickerInput}
      {...props} />
  );
};

export default React.memo(DueDateField);
