import Css from "./style.module.scss";

import DataConstants from "const/DataConstants";
import DocumentPreview from "nlib/common/DocumentPreview";
import React from "react";

const { DOCUMENT_RECOGNISE_STATUSES } = DataConstants;

const PreviewCell = ({ documentData }) => {
  const { recogniseData: { status: recogniseStatus, previewFile } } = documentData;

  return (
    <div className={Css.previewCell}>
      <DocumentPreview
        className={Css.documentPreview}
        error={recogniseStatus === DOCUMENT_RECOGNISE_STATUSES.ERROR}
        documentPreview={previewFile} />
    </div>
  );
};

export default React.memo(PreviewCell);
