import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { FieldColors } from "nlib/common/EditDocumentContent/constants";
import { Input } from "nlib/ui";
import BrowserEvents from "const/BrowserEvents";
import Button from "nlib/ui/Button";
import ConfidencePercents from "nlib/common/ConfidencePercents";
import Constants from "const/Constants";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import colorInterpolate from "color-interpolate";
import useDocumentContext from "hooks/useDocumentContext";

const { WHITE } = Constants.COLORS;

const ContainerRefContext = createContext();

const preventEventPropagation = (event) => event.stopPropagation();

const FieldContainer = (props) => {
  const {
    id,
    label,
    value,
    invalid: propsInvalid,
    disabled: propsDisabled,
    component: Component = Input,
    selectable = true,
    placeholder,
    displayValue,
    confidenceFields,
    onClick,
    onChange,
    ...restProps
  } = props;

  const containerRef = useContext(ContainerRefContext);

  const fieldRef = useRef();

  const {
    ocrMeta,
    documentFrozen,
    recogniseData: { confidence },
    localState: { activeField, hoveredField },
    setLocalState
  } = useDocumentContext();

  const { [id]: color = WHITE } = FieldColors;

  const edited = !confidence;

  const disabled = propsDisabled || documentFrozen;

  const interactive = !disabled && !edited && !!ocrMeta[id]?.boundingBox;

  const confidenceValues = !edited && confidenceFields
    ? confidenceFields?.map((field) => Utils.getPropertyByPath(confidence, field) || 0) : [];

  const confidenceValue = confidenceValues?.length ? Utils.getAverageNumber(confidenceValues) : 0;

  const invalid = !disabled && propsInvalid;

  const fieldActive = activeField?.id === id;

  const fieldHovered = hoveredField?.id === id;

  const palette = useMemo(() => {
    return colorInterpolate([color, WHITE]);
  }, [color]);

  const style = useMemo(() => {
    return {
      borderLeft: `4px solid ${interactive ? color : "#fff"}`,
      backgroundColor: (fieldActive || fieldHovered) && interactive ? palette(fieldActive ? "0.85" : "0.95") : "#fff"
    };
  }, [palette, interactive, color, fieldActive, fieldHovered]);

  const handleFieldClick = useCallback((event) => {
    if (disabled) return;
    if (onClick) onClick(event);
    if (onChange) setLocalState({ activeField: { id, selectable } });
  }, [id, selectable, disabled, onChange, onClick, setLocalState]);

  const handleMouseEnter = useCallback(() => {
    setLocalState({ hoveredField: { id } });
  }, [setLocalState, id]);

  const handleMouseLeave = useCallback(() => {
    setLocalState({ hoveredField: null });
  }, [setLocalState]);

  const handleSaveClick = useCallback((event) => {
    event.stopPropagation();
    setLocalState({ activeField: null });
  }, [setLocalState]);

  const handleWindowKeyUp = useCallback(({ key }) => {
    if (key === Constants.KEY_NAMES.ENTER) {
      setLocalState({ activeField: null });
    }
  }, [setLocalState]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (fieldHovered) {
      const { scrollTop: initialScroll } = containerRef.current;

      const elementRect = fieldRef.current.getBoundingClientRect();

      const containerRect = containerRef.current.getBoundingClientRect();

      if (elementRect.top < containerRect.top) {
        const delta = containerRect.top - elementRect.top;

        return Utils.animate((progress) => {
          containerRef.current.scrollTop = initialScroll - (delta * progress);
        });
      }

      if (elementRect.top + elementRect.height > containerRect.height + containerRect.top) {
        const delta = elementRect.top + elementRect.height - containerRect.height - containerRect.top;

        return Utils.animate((progress) => {
          containerRef.current.scrollTop = initialScroll + (delta * progress);
        });
      }
    }
  }, [fieldHovered, invalid, containerRef]);

  useEffect(() => {
    if (fieldActive) {
      window.addEventListener(BrowserEvents.KEY_UP, handleWindowKeyUp);
    }

    return () => {
      if (fieldActive) {
        window.removeEventListener(BrowserEvents.KEY_UP, handleWindowKeyUp);
      }
    };
  }, [fieldActive, handleWindowKeyUp]);

  return (
    <div
      ref={fieldRef}
      data-field={id}
      className={classNames(
        Css.fieldContainer,
        invalid && Css.invalid,
        invalid ? "invalidField" : "validField",
        fieldActive && Css.active,
        (!!onChange || !!onClick) && !disabled && Css.editable
      )}
      onClick={handleFieldClick}
      onMouseEnter={interactive ? handleMouseEnter : undefined}
      onMouseLeave={interactive ? handleMouseLeave : undefined}
      style={style}>
      <div className={Css.title}>
        <div className={Css.text}>
          {invalid && <Icons.WarningCircle />}
          <span>{label}</span>
        </div>
        {!!confidenceValue
          && !!confidenceValues?.length
          && value !== undefined
          && value !== null && (
          <ConfidencePercents
            className={Css.confidence}
            value={confidenceValue} />
        )}
      </div>
      <div className={Css.container}>
        {fieldActive && !disabled && !!onChange
          ? (
            <div className={Css.input} onClick={preventEventPropagation}>
              <Component
                autoFocus
                {...restProps}
                value={value}
                placeholder={placeholder}
                invalid={invalid}
                disabled={disabled}
                onChange={onChange} />
            </div>
          )
          : (
            <div className={Css.value}>
              {displayValue
                ? <div title={displayValue}>{displayValue}</div>
                : <div className={Css.placeholder}>{disabled ? Constants.EMPTY_PLACEHOLDER : placeholder}</div>}
            </div>
          )}
        {disabled
          ? <Button light disabled className={Css.iconButton}>
            <Icons.Lock />
          </Button>
          : (
            onChange
              ? (
                <Button
                  light
                  className={classNames(Css.iconButton, fieldActive && Css.saveButton)}
                  onClick={fieldActive ? handleSaveClick : undefined}>
                  <Icons.PencilSimple />
                </Button>
              )
              : (!onClick && (
                <Button
                  light disabled
                  className={Css.iconButton}>
                  <Icons.Lock />
                </Button>
              ))
          )}
      </div>
    </div>
  );
};

export { ContainerRefContext };

export default React.memo(FieldContainer);
