import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ActionCell from "./lib/ActionCell";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "nlib/pages/DocumentsPage/DocumentsStatuses";
import MainApiActions from "actions/MainApiActions";
import React, { useCallback } from "react";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { EMPTY_PLACEHOLDER, DATETIME_FORMATS } = Constants;

const { STATUSES: { TO_EXTRACT }, DOCUMENT_PAYMENT_TYPES } = DataConstants;

const DocumentsTable = ({ data = [], baseDocumentTable, sortBy, sortOrder, className, onSortChange }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const { id: selectedBusinessId } = useSelector(getSelectedBusinessData);

  const handlePreview = useCallback(async(id) => {
    const { recogniseData: { previewFile } = {} } = Utils.arrayFindById(data, id);

    const link = await dispatch(MainApiActions.fetchAttachmentUrl(previewFile));

    if (!link) return;

    dispatch(UiActions.showModalImages([link]));
  }, [data, dispatch]);

  return (
    <Table
      data-borderless
      data-compact
      className={classNames(Css.documentsTable, className)}
      sortBy={sortBy}
      sortOrder={sortOrder}
      onSortChange={onSortChange}>
      {!!onSortChange && (
        <TableRow>
          <TableHead className={Css.statusCell}>{uiTexts.status}</TableHead>
          <TableHead className={Css.idCell}>{`${uiTexts.document} #`}</TableHead>
          <TableHead className={Css.amountCell}>{uiTexts.total}</TableHead>
          <TableHead className={Css.addressCell}>{uiTexts.payee}</TableHead>
          <TableHead className={Css.issueDateCell}>{uiTexts.issueDate}</TableHead>
          <TableHead className={Css.lineItemsCell}>{uiTexts.description}</TableHead>
          <TableHead className={Css.actionsCell}>{uiTexts.actions}</TableHead>
        </TableRow>
      )}
      {data.map((document) => {
        const {
          id,
          status,
          currency,
          issueDate,
          lineItems,
          amountVat,
          amountBase,
          paymentType,
          originalDocumentId,
          attachment = {},
          recogniseData = {}
        } = document;

        const {
          labelLangId,
          iconComponent: StatusIcon,
          errorIconComponent: ErrorStatusIcon
        } = DocumentsStatuses.getStatusData(status, activeOrganization.countryCode);

        const errorState = recogniseData.status === "error";

        const buyPayment = paymentType === DOCUMENT_PAYMENT_TYPES.BUY;

        const amountValue = (+amountBase || 0) + (+amountVat || 0);

        const amountContent = Utils.toMoneyString(amountValue, currency);

        const lineItemsContent = lineItems && lineItems.map(({ description, quantity }) => {
          return `${description} ${quantity ? `[x${quantity || 1}]` : ""}`.trim();
        }).join("; ");

        return (
          <TableRow key={id} className={classNames(Css.tableRow, baseDocumentTable && Css.warning)}>
            <TableCell className={Css.statusCell}>
              <div title={uiTexts[labelLangId]} data-status={errorState ? recogniseData.status : status}>
                {errorState ? <ErrorStatusIcon /> : <StatusIcon />}
              </div>
            </TableCell>
            <TableCell className={Css.idCell}>
              {status === TO_EXTRACT
                ? EMPTY_PLACEHOLDER
                : (
                  <a
                    href={`/${selectedBusinessId}${UiRoutes.DOCUMENTS}?editItem=${id}`}
                    title={originalDocumentId || ""}
                    target="_blank">
                    <Icons.FileText className={attachment.key ? "" : Css.textNegative} />
                    <span>{originalDocumentId || "n/a"}</span>
                  </a>
                )}
            </TableCell>
            <TableCell className={Css.amountCell}>
              <div
                title={amountContent}
                className={classNames(Css.amount, paymentType && Css.coloredNumber)}
                data-negative={buyPayment ? "" : undefined}>
                {amountContent}
              </div>
            </TableCell>
            <TableCell className={Css.addressCell}>
              {document.address && document.address.name
                ? <div title={document.address.name}>
                  <Icons.User />
                  <span>{document.address.name}</span>
                </div>
                : <div>{Constants.EMPTY_PLACEHOLDER}</div>}
            </TableCell>
            <TableCell className={Css.issueDateCell}>
              {issueDate ? moment.utc(issueDate).format(DATETIME_FORMATS.DATE_TEXT) : EMPTY_PLACEHOLDER}
            </TableCell>
            <TableCell className={Css.lineItemsCell}>
              <div title={lineItemsContent || ""}>{lineItemsContent || EMPTY_PLACEHOLDER}</div>
            </TableCell>
            <TableCell className={classNames(Css.actionsCell, Css.tableBodyCell)}>
              <ActionCell id={id} onPreview={handlePreview} />
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default React.memo(DocumentsTable);
