import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import {
  getCurrentXeroOrganizationId,
  getCurrentZohoOrganizationId,
  getSelectedBusinessUsersData
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import DataConstants from "const/DataConstants";
import DatePickerInput from "nlib/common/DatePickerInput";
import DateRangePickerExtended from "nlib/common/DateRangePickerExtended";
import DebounceInput from "nlib/ui/DebounceInput";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Row from "nlib/ui/Row";
import Select from "nlib/ui/Select";
import SelectPaidWith from "./lib/SelectPaidWith";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const { DOCUMENT_PAYMENT_TYPES: { SELL, BUY } } = DataConstants;

const Filters = ({ simplifyLayout, disabled }) => {
  const [envVars, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const {
    text = "",
    fromDate = "",
    toDate = "",
    type,
    paidWith,
    createdBy,
    createdAt
  } = envVars;

  const [textInputValue, setTextInputValue] = useState(text);

  const fromToDate = useMemo(() => [fromDate, toDate], [fromDate, toDate]);

  const filtersActive = [text, fromDate, toDate, type, paidWith, createdBy, createdAt].some(Boolean);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const usersData = useSelector(getSelectedBusinessUsersData);

  const typeOptions = useMemo(() => [
    type && { label: uiTexts.all },
    { value: SELL, label: uiTexts.revenue },
    { value: BUY, label: uiTexts.expenses }
  ], [type, uiTexts]);

  const uploadedByOptions = useMemo(() => {
    return usersData.map(({ id, fullName }) => ({ value: id, label: fullName }));
  }, [usersData]);

  const handleTextInputComplete = useCallback((value) => {
    setEnvVars({ text: value || null });
  }, [setEnvVars]);

  const handleDateChange = useCallback((value) => {
    const [from, to] = value;

    setEnvVars({ fromDate: from, toDate: to });
  }, [setEnvVars]);

  const handleTypeChange = useCallback((value) => {
    setEnvVars({ type: value });
  }, [setEnvVars]);

  const handlePaidWithChange = useCallback((value) => {
    setEnvVars({ paidWith: value });
  }, [setEnvVars]);

  const handleUploadedByChange = useCallback((value) => {
    setEnvVars({ createdBy: value });
  }, [setEnvVars]);

  const handleCreateAtInputChange = useCallback((value) => {
    setEnvVars({ createdAt: value });
  }, [setEnvVars]);

  const handleResetButtonClick = useCallback(() => {
    setEnvVars({
      text: null,
      fromDate: null,
      toDate: null,
      type: null,
      paidWith: null,
      createdBy: null,
      createdAt: null
    });
  }, [setEnvVars]);

  useEffect(() => {
    setTextInputValue(text);
  }, [text]);

  return (
    <div className={classNames(Css.filters, filtersActive && Css.active)} disabled={disabled}>
      <Row>
        <DebounceInput
          cleanable
          active={!!text}
          className={Css.search}
          placeholder={uiTexts.searchDocuments}
          value={textInputValue}
          onChange={setTextInputValue}
          onInputComplete={handleTextInputComplete}
          iconBefore={Icons.MagnifyingGlass} />
        <DateRangePickerExtended
          range
          active={!!fromDate && !!toDate}
          value={fromToDate}
          className={Css.dateRange}
          placeholder={uiTexts.selectDate}
          onChange={handleDateChange} />
        <Select
          active={!!type}
          className={Css.filter}
          placeholder={uiTexts.selectType}
          iconBefore={Icons.CurrencyCircleDollar}
          value={type}
          options={typeOptions}
          onChange={handleTypeChange} />
        <Select
          active={!!createdBy}
          className={Css.filter}
          placeholder={uiTexts.uploadedBy}
          iconBefore={Icons.User}
          value={createdBy}
          options={uploadedByOptions}
          onChange={handleUploadedByChange} />
        {!simplifyLayout && (
          <>
            {(xeroBusiness || zohoBusiness) && (
              <SelectPaidWith
                className={Css.filter}
                placeholder={uiTexts.paidFromTo}
                active={!!paidWith}
                value={paidWith}
                onChange={handlePaidWithChange} />
            )}
            <DatePickerInput
              active={!!createdAt}
              value={createdAt}
              className={classNames(Css.filter, Css.date)}
              placeholder={uiTexts.uploadDate}
              onChange={handleCreateAtInputChange} />
          </>
        )}
        <Button
          large outline danger
          className={classNames(Css.resetButton, filtersActive && Css.active)}
          icon={Icons.X}
          onClick={handleResetButtonClick}>
          {uiTexts.resetFilters}
        </Button>
      </Row>
    </div>
  );
};

export default React.memo(Filters);
