import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Checkbox } from "nlib/ui";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DropArea from "nlib/common/DropArea";
import DropBoxPicker from "nlib/common/DropBoxPicker";
import GoogleDrivePicker from "nlib/common/GoogleDrivePicker";
import IntegrationServices from "const/IntegrationServices";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useMemo, useState } from "react";
import Select from "nlib/ui/Select";
import Switcher from "nlib/ui/Switcher";
import Utils from "utils/Utils";

const {
  DOCUMENT_TYPES: { BILL, INVOICE, RECEIPT, SALES_RECEIPT },
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL }
} = DataConstants;

const FILE_DROP_ALLOWED_EXTENSIONS = Object.values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions }) => [...aggregator, ...extensions], []);

const FILE_DROP_ALLOWED_TYPES = Object.values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const ImportDocumentsWindow = ({ onClose, ...restProps }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const {
    extraData: { integrationService } = {},
    meta: { emailAddress }
  } = useSelector(getSelectedBusinessData);

  const [activeTypeTab, setActiveTypeTab] = useState(null);

  const [paymentType, setPaymentType] = useState(null);

  const [type, setType] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState(null);

  const [merge, setMerge] = useState(false);

  const quickBooksBusiness = integrationService === IntegrationServices.QUICK_BOOKS.value;

  const options = useMemo(() => {
    return [
      { value: null, label: uiTexts.auto },
      { value: INVOICE, label: uiTexts.invoice },
      { value: BILL, label: uiTexts.bill },
      { value: RECEIPT, label: uiTexts.receipt },
      ...(quickBooksBusiness
        ? [{ value: SALES_RECEIPT, label: uiTexts.salesReceipt }]
        : [])
    ];
  }, [quickBooksBusiness, uiTexts]);

  const TypeSelectComponent = Utils.checkIsTouchDevice() ? Select : Switcher;

  const handleTypeTabChange = useCallback((value) => {
    setActiveTypeTab(value);
    setType(value === BILL ? INVOICE : value);
    setPaymentType(
      (value === INVOICE || value === SALES_RECEIPT) ? SELL
        : (
          (value === BILL || value === RECEIPT) ? BUY : ""
        )
    );
  }, []);

  const handleMergeButtonClick = useCallback(() => {
    setMerge((prev) => !prev);
  }, []);

  const handleFilesDrop = useCallback((files, fileRejections) => {
    if (files.length) {
      setSelectedFiles(files);
    } else if (fileRejections.length) {
      setSelectedFiles(null);
    }
  }, []);

  const handleGoogleDrivePickerFilesPicked = useCallback((docs, oAuthToken) => {
    onClose({
      cloudService: "gdrive",
      oAuthToken,
      googleDocs: docs,
      type,
      paymentType,
      merge
    });
  }, [onClose, type, paymentType, merge]);

  const handleDropBoxPickerFilesPicked = useCallback((files) => {
    onClose({
      cloudService: "dropbox",
      filesLinks: files,
      type,
      paymentType,
      merge
    });
  }, [onClose, type, paymentType, merge]);

  const handleCloseButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleImportButtonClick = useCallback(() => {
    onClose({
      merge,
      type,
      paymentType,
      files: selectedFiles
    });
  }, [onClose, merge, type, paymentType, selectedFiles]);

  const handleEmailClick = useCallback(() => {
    navigator.clipboard.writeText(emailAddress);
    toast.info(uiTexts.copied);
  }, [emailAddress, uiTexts.copied]);

  return (
    <Modal
      {...restProps}
      className={Css.uploadDocumentsDialog}
      iconComponent={Icons.FileText}
      title={uiTexts.uploadDocuments}
      onClose={onClose}>
      <TypeSelectComponent
        className={Css.switcher}
        options={options}
        value={activeTypeTab}
        placeholder={uiTexts.selectType}
        onChange={handleTypeTabChange} />
      <DropArea
        multiple
        className={Css.dropArea}
        extensions={FILE_DROP_ALLOWED_EXTENSIONS}
        accept={FILE_DROP_ALLOWED_TYPES}
        onDrop={handleFilesDrop} />
      {!Utils.checkIsTouchDevice() && (
        <>
          <div>{messages.uploadFromCloudStorage}:</div>
          <div className={Css.cloudDocumentsPickers}>
            <GoogleDrivePicker onFilesPicked={handleGoogleDrivePickerFilesPicked} />
            <DropBoxPicker onFilesPicked={handleDropBoxPickerFilesPicked} />
          </div>
        </>
      )}
      <div className={Css.autoUploadEmailGroup}>
        <div>{messages.autoUploadDocuments}</div>
        <div className={Css.email} onClick={handleEmailClick}>
          <span>{emailAddress}</span>
          <Icons.Copy />
        </div>
      </div>
      <div className={Css.mergeCheckbox}>
        <Button outline large onClick={handleMergeButtonClick}>
          <Checkbox
            toggle
            value={merge}>
            {messages.mergeDocumentsCheckbox}
          </Checkbox>
        </Button>
      </div>
      <div className={Css.actions}>
        <Button
          primary large
          disabled={!selectedFiles || (merge && selectedFiles?.length === 1)}
          onClick={handleImportButtonClick}>
          {selectedFiles?.length
            ? Utils.replaceTextVars(
              merge ? uiTexts.mergeCountDocuments : uiTexts.uploadCountDocuments,
              { count: selectedFiles.length }
            )
            : (merge ? uiTexts.mergeDocuments : uiTexts.uploadDocuments)}
        </Button>
        <Button large outline onClick={handleCloseButtonClick}>
          {uiTexts.close}
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(ImportDocumentsWindow);
