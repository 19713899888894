import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useSelector } from "react-redux";
import ActivityTab from "./lib/ActivityTab";
import DataConstants from "const/DataConstants";
import DocumentTab from "./lib/DocumentTab";
import FormContext from "contexts/FormContext";
import React, { useCallback, useContext, useMemo } from "react";
import Tabs, { Tab } from "nlib/ui/Tabs";
import UserRoles from "const/UserRoles";
import useEnvVars from "hooks/useEnvVars";

const {
  STATUSES: { TO_REPORT, EXCLUDED, EXPORTED }
} = DataConstants;

const DOCUMENT_FROZEN_STATUSES = [TO_REPORT, EXCLUDED, EXPORTED];

const MODES = {
  DEFAULT: "default",
  LINE_ITEMS: "lineItems",
  ACTIVITY: "comments"
};

const OldEditForm = ({ disabled, documentData, refetchDocument }) => {
  const [{ editItem }, setEnvVars] = useEnvVars();

  const [editDocumentId, currentTab = MODES.DEFAULT] = editItem ? editItem.split(".") : [];

  const { uiTexts } = useSelector(getTextsData);

  const userRole = useSelector(getUserRole);

  const { values: { lineItems = [] } } = useContext(FormContext);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const documentFrozen = DOCUMENT_FROZEN_STATUSES.includes(documentData.status);

  const lineItemsValid = useMemo(() => {
    return lineItems.every(({ description, amountTotal = null, category = {} }) => {
      return description && amountTotal !== null && (businessUser || category.code);
    });
  }, [businessUser, lineItems]);

  const handleTabsChange = useCallback((tab) => {
    setEnvVars({ editItem: tab === MODES.DEFAULT ? editDocumentId : `${editDocumentId}.${tab}` });
  }, [editDocumentId, setEnvVars]);

  return (
    <>
      <Tabs disabled={disabled} className={Css.tabs} current={currentTab} onChange={handleTabsChange}>
        <Tab className={Css.tab} value={MODES.DEFAULT}>
          <Icons.NotePencil /><span>{uiTexts.document}</span>
        </Tab>
        <Tab className={Css.tab} value={MODES.ACTIVITY}>
          <Icons.Lightning /><span>{uiTexts.activity}</span>
        </Tab>
      </Tabs>
      {({
        [MODES.DEFAULT]: (
          <DocumentTab
            disabled={disabled}
            documentData={documentData}
            lineItemsValid={lineItemsValid}
            documentFrozen={documentFrozen}
            refetchDocument={refetchDocument} />
        ),
        [MODES.ACTIVITY]: <ActivityTab disabled={disabled} documentData={documentData} />
      }[currentTab])}
    </>
  );
};

export default React.memo(OldEditForm);
