import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getCurrenciesData } from "selectors/metaData";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useMainApi } from "hooks";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import DocumentsTable from "./lib/DocumentsTable";
import MainApiRoutes from "const/MainApiRoutes";
import Modal from "nlib/ui/Modal";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import RestApi from "api/RestApi";
import Utils from "utils/Utils";

const DuplicatedDocumentsWindow = ({ documentData, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const currenciesData = useSelector(getCurrenciesData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const [modalShown, setModalShown] = useState(false);

  const [tableSorting, setTableSorting] = useState({});

  const { duplicatedDocumentsIds = [] } = documentData;

  const [{ results: responseResult }, fetchingData] = useMainApi({
    method: RestApi.REQUEST_METHODS.POST,
    initialData: { results: [] },
    fetchCondition: modalShown,
    parameters: [
      `${MainApiRoutes.BUSINESSES}/${selectedBusinessId}${MainApiRoutes.DOCUMENTS}`,
      null,
      { documentsIds: duplicatedDocumentsIds }
    ],
    dependencies: [modalShown]
  });

  const duplicatedDocuments = useMemo(() => {
    return responseResult.filter(({ id }) => duplicatedDocumentsIds.includes(id))
      .map(({ currency, ...restDocument }) => ({
        ...restDocument,
        currency: currenciesData.some(({ code }) => code === currency) ? currency : null
      }));
  }, [responseResult, duplicatedDocumentsIds, currenciesData]);

  const duplicatedDocumentsSorted = useMemo(() => {
    return Utils.arraySort(duplicatedDocuments, (item) => {
      switch (tableSorting.sortBy) {
        case "address":
          return item.address ? item.address.name : "";
        default:
          return item[tableSorting.sortBy];
      }
    }, tableSorting.sortOrder === "asc");
  }, [tableSorting, duplicatedDocuments]);

  const handleModalShow = useCallback(() => {
    setModalShown(true);
  }, []);

  const handleEditClick = useCallback(() => {
    onClose({ id: documentData.id, duplicatedDocumentsIds: [] });
  }, [documentData.id, onClose]);

  const handleDeleteClick = useCallback(() => {
    onClose({ id: documentData.id, deleteDocument: true });
  }, [documentData.id, onClose]);

  const handleTableSortChange = useCallback((newTableSorting) => {
    setTableSorting(newTableSorting);
  }, []);

  const handleCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!fetchingData && duplicatedDocuments.length === 0) {
      onClose({ id: documentData.id, duplicatedDocumentsIds: [] });
    }
  }, [fetchingData, duplicatedDocuments, onClose, documentData.id]);

  return (
    <Modal
      className={Css.duplicatedDocumentsWindow}
      dialogClassName={Css.dialogClassName}
      iconComponent={Icons.Copy}
      title={uiTexts.similarDocuments}
      buttons={(
        <>
          <Button
            primary
            large
            disabled={fetchingData || !duplicatedDocuments.length}
            onClick={handleEditClick}>{uiTexts.continueEditing}</Button>
          <Button
            danger
            large
            disabled={fetchingData || !duplicatedDocuments.length}
            onClick={handleDeleteClick}>{uiTexts.deleteDocument}</Button>
          <Button
            large
            disabled={fetchingData || !duplicatedDocuments.length}
            onClick={handleCloseClick}>{uiTexts.close}</Button>
        </>
      )}
      onShow={handleModalShow}
      onClose={onClose}>
      <div className={Css.content}>
        {(fetchingData || !duplicatedDocuments.length)
          ? <Preloader absolute />
          : (
            <>
              <DocumentsTable
                baseDocumentTable
                data={[documentData]}
                sortBy={tableSorting.sortBy}
                sortOrder={tableSorting.sortOrder}
                onSortChange={handleTableSortChange} />
              <div className={Css.message}>
                <Icons.Warning />
                <span>{`${messages.duplicatedDocumentsMessage}:`}</span>
              </div>
              <DocumentsTable data={duplicatedDocumentsSorted} />
            </>
          )}
      </div>
    </Modal>
  );
};

export default React.memo(DuplicatedDocumentsWindow);
