import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";

const { STATUSES } = DataConstants;

const MAX_VISIBLE_DESCRIPTION_LENGTH = 200;

const DescriptionCell = ({ documentData }) => {
  const {
    lineItems,
    status,
    recogniseData: { status: recogniseStatus },
    attachment = {},
    lastExportErrors
  } = documentData;

  const { uiTexts, errors } = useSelector(getTextsData);

  if ((status === STATUSES.TO_REVIEW) && Array.isArray(lastExportErrors) && lastExportErrors.length) {
    return (
      <AutoTooltip className={CommonCss.negativeText}>
        <Icons.Warning weight="bold" className={Css.errorIcon} />
        <span><b>{`${errors.exportDocumentError}: ${lastExportErrors.join("; ")}`}</b></span>
      </AutoTooltip>
    );
  }

  const fileName = attachment.originalName;

  const extractStatus = status === STATUSES.TO_EXTRACT;

  const errorState = recogniseStatus === "error";

  const uniqueDescriptions = lineItems
    ? [...new Set(lineItems.map(({ description = "" }) => description.trim()).filter(Boolean))] : [];

  let content = extractStatus
    ? (errorState ? errors.ocrError : uiTexts.processing)
    : uniqueDescriptions.join(", ");

  if (extractStatus && fileName) content = `${content}: ${fileName}`;
  if (content.length > MAX_VISIBLE_DESCRIPTION_LENGTH) {
    content = `${content.slice(0, MAX_VISIBLE_DESCRIPTION_LENGTH)}...`;
  }

  return errorState
    ? (
      <AutoTooltip className={CommonCss.negativeText}>
        <span><b>{content}</b></span>
      </AutoTooltip>
    )
    : (
      <AutoTooltip active={!!content}>
        {content || Constants.EMPTY_PLACEHOLDER}
      </AutoTooltip>
    );
};

export default React.memo(DescriptionCell);
