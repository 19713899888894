import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useMemo } from "react";
import Select from "nlib/ui/Select";

const {
  DOCUMENT_TYPES: { INVOICE, BILL, RECEIPT, SALES_RECEIPT },
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL }
} = DataConstants;

const DOCUMENT_TYPES_REDUCED = [INVOICE, BILL, RECEIPT, SALES_RECEIPT];

const VALUES = {
  [INVOICE]: { [BUY]: BILL, [SELL]: INVOICE },
  [RECEIPT]: { [BUY]: RECEIPT },
  [SALES_RECEIPT]: { [SELL]: SALES_RECEIPT }
};

const DocumentTypeSelect = (props) => {
  const { value, type, paymentType, ...restProps } = props;

  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => {
    return DOCUMENT_TYPES_REDUCED
      .map((item) => ({ value: item, label: uiTexts[item] }));
  }, [uiTexts]);

  return (
    <Select
      {...restProps}
      value={VALUES?.[type]?.[paymentType] || ""}
      placeholder={uiTexts.selectDocumentType}
      options={options} />
  );
};

export default React.memo(DocumentTypeSelect);
