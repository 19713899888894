import CommonCss from "nlib/common/common.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getCurrentXeroOrganizationId, getSelectedBusinessData } from "selectors/businesses";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";

const { EMPTY_PLACEHOLDER } = Constants;

const { STATUSES: { EXPORTED } } = DataConstants;

const AddressCell = ({ documentData }) => {
  const { vendorId, paymentType, status, address: { name } = {} } = documentData;

  const { settings: { allowClientPayeeSelection } = {} } = useSelector(getSelectedBusinessData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const content = (xeroBusiness || paymentType) && name;

  const exportedStatus = status === EXPORTED;

  const businessUser = useSelector(checkIsBusinessUser);

  const vendorIcon = vendorId
    ? <Icons.User className={CommonCss.positiveText} />
    : <Icons.UserPlus className={CommonCss.highlightText} />;

  return (
    <AutoTooltip active={!!content}>
      {!!content && (exportedStatus || (businessUser && !allowClientPayeeSelection)
        ? <Icons.User />
        : vendorIcon)}
      <span>{content || EMPTY_PLACEHOLDER}</span>
    </AutoTooltip>
  );
};

export default React.memo(AddressCell);
