import { useCallback } from "react";
import { useDispatch } from "react-redux";
import Async from "utils/Async";
import BusinessesActions from "actions/BusinessesActions";
import UiActions from "actions/UiActions";

const useDocumentUtils = () => {
  const dispatch = useDispatch();

  const openPairedTransactionsWindow = useCallback(async(documentData) => {
    const result = await dispatch(UiActions.showPairTransactionsWindow({ documentData }));

    if (!result) return false;

    const { documentId, transactionId, unpair } = result;

    const { paidTransactions } = documentData;

    if (!unpair) {
      if (paidTransactions?.length) {
        await Async.runInSequence(paidTransactions.map(({ id }) => {
          return () => dispatch(BusinessesActions.unpairMatches(id, documentId));
        }));
      }
    }

    const actionCreator = unpair ? BusinessesActions.unpairMatches : BusinessesActions.pairMatches;

    return dispatch(actionCreator(transactionId, documentId));
  }, [dispatch]);

  return {
    openPairedTransactionsWindow
  };
};

export default useDocumentUtils;
