import { checkIsBusinessUser } from "selectors/user";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import DatePickerInput from "nlib/common/DatePickerInput";
import FieldContainer from "../FieldContainer";
import React, { useCallback } from "react";
import Utils from "utils/Utils";
import moment from "moment";
import useDateFormatter from "hooks/useDateFormatter";
import useDocumentContext from "hooks/useDocumentContext";

const {
  DOCUMENT_TYPES: { RECEIPT },
  ADVANCED_TRANSACTION_TYPES: { EXPENSE }
} = DataConstants;

const IssueDateField = (props) => {
  const id = "issueDate";

  const dateFormatter = useDateFormatter();

  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const businessUser = useSelector(checkIsBusinessUser);

  const {
    documentState: { [id]: value, type, exportAs },
    setDocumentState
  } = useDocumentContext();

  const label = quickBooksBusiness && type === RECEIPT && exportAs === EXPENSE
    ? uiTexts.paymentDate
    : uiTexts.issueDate;

  const placeholder = quickBooksBusiness && type === RECEIPT && exportAs === EXPENSE
    ? uiTexts.enterPaymentDate
    : uiTexts.enterIssueDate;

  const handleChange = useCallback((newValue) => {
    setDocumentState({ [id]: newValue });
  }, [setDocumentState]);

  return (
    <FieldContainer
      portal
      id={id}
      confidenceFields={["issueDate"]}
      label={label}
      placeholder={placeholder}
      invalid={!businessUser
        && (!value || moment(value).isAfter(moment(Utils.formatNoTimeZoneDate(new Date(), false))))}
      value={value}
      displayValue={value ? dateFormatter(value) : null}
      onChange={handleChange}
      component={DatePickerInput}
      {...props} />
  );
};

export default React.memo(IssueDateField);
