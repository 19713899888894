import Constants from "const/Constants";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import Documents from "utils/Documents";
import Utils from "utils/Utils";

const searchContact = (contactsData, { id, idNumber, vatId, name }, { paymentType, integrationService, xeroBusiness }) => {
  const {
    DOCUMENT_PAYMENT_TYPES: { BUY },
    CONTACT_SUB_TYPES: { VENDOR, CUSTOMER }
  } = DataConstants;

  const buyPaymentType = paymentType === BUY;

  const contactData = contactsData.find((contact) => {
    if (paymentType && integrationService && !xeroBusiness) {
      if ((buyPaymentType && contact.subType !== VENDOR) || (!buyPaymentType && contact.subType !== CUSTOMER)) {
        return false;
      }
    }
    if (id && id === contact.id) return true;

    return Utils.checkSimilarityBy({ idNumber, vatId, name }, contact);
  });

  return contactData;
};

export const getInitialState = (documentData, extraData) => {
  if (!documentData) return null;

  const {
    DOCUMENT_TYPES: { RECEIPT },
    DOCUMENT_PAYMENT_TYPES: { BUY },
    ADVANCED_TRANSACTION_TYPES: { EXPENSE },
    CONTACT_SUB_TYPES: { VENDOR, CUSTOMER }
  } = DataConstants;

  const defaultPayTo = { bankAccount: "", bankCode: "", bankName: "", serviceAccountId: "" };

  const { xeroBusiness, quickBooksBusiness, countryCode, currenciesData } = extraData;

  const { detailedTaxRates, paymentType, lineItems, recogniseData = {} } = documentData || {};

  const usCountry = countryCode === Countries.US;

  const amountBase = Utils.toMoneyNumber(documentData.amountBase) || 0;

  const amountVat = Utils.toMoneyNumber(documentData.amountVat) || 0;

  const receiptType = documentData.type === RECEIPT;

  const buyPaymentType = paymentType === BUY;

  const { addresses: { recipient = {}, sender = {} } = {} } = recogniseData;

  const addressData = paymentType && (documentData.address || (buyPaymentType ? sender : recipient));

  return {
    ...documentData,
    amountBase,
    amountVat,
    amount: Utils.toMoneyNumber(amountBase + amountVat),
    exportAs: documentData.exportAs || (quickBooksBusiness && receiptType ? EXPENSE : ""),
    currency: currenciesData.some(({ code }) => code === documentData.currency) ? documentData.currency : "",
    paid: (quickBooksBusiness && receiptType && documentData.exportAs === EXPENSE) || !!documentData.paid,
    payTo: Utils.getProps(documentData.payTo, Object.keys(defaultPayTo), defaultPayTo),
    address: {
      ...((xeroBusiness || paymentType) ? addressData : {}),
      subType: (!xeroBusiness && paymentType) ? (paymentType === BUY ? VENDOR : CUSTOMER) : ""
    },
    detailedTaxRates: detailedTaxRates?.length ? detailedTaxRates
      : (quickBooksBusiness && !usCountry
        ? Documents.getDetailedTaxRates(paymentType, lineItems)
        : []),
    lineItems: lineItems && lineItems.map((lineItem) => ({
      ...lineItem,
      item: lineItem.item?.id ? lineItem.item : undefined // TEMPORARY FIX
    }))
  };
};

export const recalculateLineItemFields = (prev, fieldName, value) => {
  switch (fieldName) {
    case "amountBase": {
      const { vatRate } = prev;

      const amountVat = value && vatRate ? Utils.toMoneyNumber(value * vatRate / Constants.PERCENTS_MULTIPLIER) : 0;

      const amountTotal = Utils.toMoneyNumber((value || 0) + (amountVat || 0));

      return { ...prev, [fieldName]: value, amountVat, amountTotal };
    }
    case "amountVat": {
      const { amountBase } = prev;

      const vatRate = value && amountBase ? Utils.toMoneyNumber(value / amountBase * Constants.PERCENTS_MULTIPLIER) : 0;

      const amountTotal = Utils.toMoneyNumber((amountBase || 0) + (value || 0));

      return { ...prev, [fieldName]: value, amountTotal, vatRate };
    }
    case "vatRate": {
      const { amountBase } = prev;

      const amountVat = value && amountBase
        ? Utils.toMoneyNumber(amountBase * value / Constants.PERCENTS_MULTIPLIER)
        : 0;

      const amountTotal = Utils.toMoneyNumber((amountBase || 0) + (amountVat || 0));

      return { ...prev, [fieldName]: value, amountVat, amountBase, amountTotal };
    }
    case "taxRate": {
      const { amountBase } = prev;

      if (value?.id) {
        const amountVat = amountBase
          ? Utils.toMoneyNumber(amountBase * value.rate / Constants.PERCENTS_MULTIPLIER)
          : 0;

        const amountTotal = Utils.toMoneyNumber((amountBase || 0) + (amountVat || 0));

        return { ...prev, [fieldName]: value, vatRate: value.rate, amountVat, amountTotal };
      }

      return { ...prev, [fieldName]: value, vatRate: 0, amountVat: 0, amountTotal: amountBase };
    }
    case "category":
      return { ...prev, [fieldName]: value, item: undefined };
    case "item":
      return { ...prev, [fieldName]: value, category: undefined };
    default:
      return { ...prev, [fieldName]: value };
  }
};

export const updateState = (update, state, extraData) => {
  const {
    DOCUMENT_TYPES: { RECEIPT },
    DOCUMENT_PAYMENT_TYPES: { BUY },
    ADVANCED_TRANSACTION_TYPES: { EXPENSE },
    CONTACT_SUB_TYPES: { VENDOR, CUSTOMER }
  } = DataConstants;

  const {
    recogniseData,
    countryCode,
    quickBooksBusiness,
    xeroBusiness,
    integrationService,
    contactsData,
    status
  } = extraData;

  const keysToUpdate = Object.keys(update);

  const { lineItems, amountVat, amountBase, paymentType, paymentDate, dueDate, issueDate } = state;

  const czCountry = countryCode === Countries.CZ;

  const usCountry = countryCode === Countries.US;

  keysToUpdate.forEach((key) => {
    switch (key) {
      case "amountBase": {
        update.amount = Utils.toMoneyNumber((update.amountBase || 0) + (amountVat || 0));
        if (lineItems.length === 1) {
          update.lineItems = [recalculateLineItemFields(lineItems[0], "amountBase", Utils.toMoneyNumber(update.amountBase || 0))];
        }
        break;
      }
      case "amountVat": {
        update.amount = Utils.toMoneyNumber((update.amountVat || 0) + (amountBase || 0));
        if (lineItems.length === 1) {
          update.lineItems = [recalculateLineItemFields(lineItems[0], "amountVat", Utils.toMoneyNumber(update.amountVat || 0))];
        }
        break;
      }
      case "amount": {
        update.amountBase = Utils.toMoneyNumber(Math.max((update.amount || 0) - (state.amountVat || 0), 0));
        if (lineItems.length === 1) {
          update.lineItems = [recalculateLineItemFields(lineItems[0], "amountBase", Utils.toMoneyNumber(update.amountBase || 0))];
        }
        break;
      }
      case "payTo": {
        if (update.payTo?.serviceAccountId) {
          update.paid = true;
          if (!paymentDate && (dueDate || issueDate)) {
            update.paymentDate = Utils.formatApiDate(dueDate || issueDate);
          }
        }
        break;
      }
      case "exportAs": {
        update.paid = update.exportAs === EXPENSE;
        update.payTo = {};
        break;
      }
      case "detailedTaxRates": {
        update.amountVat = update.detailedTaxRates
          .reduce((aggregator, currentValue) => aggregator + +(currentValue.value || 0), 0);
        update.amount = Utils.toMoneyNumber((update.amountVat || 0) + (amountBase || 0));
        break;
      }
      case "address": {
        update.vendorId = update.address.id;
        break;
      }
      case "paymentType": {
        const buyPaymentType = update.paymentType === BUY;

        const { payTo: { serviceAccountId, ...restPayTo } = {} } = state;

        const { addresses: { recipient = {}, sender = {} } = {} } = recogniseData;

        const nextAddress = buyPaymentType ? sender : recipient;

        const contactSubType = czCountry ? "" : (buyPaymentType ? VENDOR : CUSTOMER);

        update.exportAs = quickBooksBusiness && update.type === RECEIPT ? EXPENSE : "";
        update.paid = !!(quickBooksBusiness && update.type === RECEIPT);
        update.payTo = restPayTo;
        update.linkedDocumentsIds = [];
        if (status === DataConstants.STATUSES.TO_REVIEW) {
          const contactData = searchContact(contactsData, nextAddress, {
            paymentType: update.paymentType,
            integrationService,
            xeroBusiness
          });

          update.address = contactData || { ...nextAddress, subType: contactSubType };
          update.vendorId = contactData ? contactData.id : undefined;
        }
        if (quickBooksBusiness && !usCountry) {
          const detailedTaxRates = Documents.getDetailedTaxRates(update.paymentType, lineItems);

          update.detailedTaxRates = detailedTaxRates;
          update.amountVat = detailedTaxRates.reduce((aggregator, currentValue) => aggregator + currentValue.value || 0, 0);
        }
        break;
      }
      case "lineItems": {
        if (!czCountry) {
          const prevLineItemsAmounts = Documents.calculateLineItemsData({
            lineItems, paymentType, countryCode, quickBooksBusiness });

          const lineItemsAmounts = Documents.calculateLineItemsData({
            lineItems: update.lineItems, paymentType, countryCode, quickBooksBusiness });

          if (prevLineItemsAmounts.amount !== lineItemsAmounts.amount) {
            update.amountBase = lineItemsAmounts.amountBase;
            update.amountVat = lineItemsAmounts.amountVat;
            update.amount = lineItemsAmounts.amount;
            update.tags = [
              ...new Set(
                lineItems.map(({ category, item }) => {
                  category = category || {};
                  item = item || {};

                  return (category.code || item.id) ? (category.name || item.name) : null;
                }).filter(Boolean)
              )
            ];
          }
          if (lineItemsAmounts.detailedTaxRates) {
            update.detailedTaxRates = lineItemsAmounts.detailedTaxRates;
          }
        }
        break;
      }
      default:
        break;
    }
  });

  return update;
};
