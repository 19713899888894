import CommonCss from "nlib/common/common.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getAccountsData } from "selectors/accounts";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoTooltip from "nlib/ui/AutoTooltip";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useMemo } from "react";

const { STATUSES } = DataConstants;

const PaymentAccountCell = ({ serviceAccountId, status, paymentType, paidTransactions = [] }) => {
  const { uiTexts } = useSelector(getTextsData);

  const { extraData: { xeroClassification, zohoClassification } = {} } = useSelector(getSelectedBusinessData);

  const accountsData = useSelector(getAccountsData);

  const categories = useMemo(() => {
    return (xeroClassification || zohoClassification || []).filter(({ paymentsAllowed }) => paymentsAllowed);
  }, [xeroClassification, zohoClassification]);

  const extractionStatus = status === STATUSES.TO_EXTRACT;

  const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

  const accountData = !extractionStatus && (serviceAccountId
    ? (accountsData.find(({ extraData: { xeroAccountId, zohoAccountId } = {} }) =>
      (xeroAccountId || zohoAccountId) === serviceAccountId)
        || categories.find(({ accountId }) => accountId === serviceAccountId))
    : (paidTransactions && paidTransactions.length && accountsData.find(({ id }) => id === paidTransactions[0].account.id)));

  const content = accountData ? (accountData.displayName || accountData.name) : uiTexts.no;

  return (
    <AutoTooltip active={!!accountData}>
      {!!(accountData && paymentType) && (buyPaymentType
        ? <Icons.ArrowLeft className={CommonCss.negativeText} />
        : <Icons.ArrowRight className={CommonCss.positiveText} />)}
      <span>{extractionStatus ? Constants.EMPTY_PLACEHOLDER : content}</span>
    </AutoTooltip>
  );
};

export default React.memo(PaymentAccountCell);
