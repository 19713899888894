import Constants from "const/Constants";
import React from "react";
import moment from "moment";

const { DATETIME_FORMATS: { DATE_TEXT } } = Constants;

const IssueDateCell = ({ documentData: { issueDate } }) => {
  return (
    issueDate ? moment.utc(issueDate).format(DATE_TEXT) : Constants.EMPTY_PLACEHOLDER
  );
};

export default React.memo(IssueDateCell);
