import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Card from "nlib/common/Card";
import Popup from "nlib/ui/Popup";
import React, { useCallback, useEffect, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const DocumentsPopup = ({ ids = [], disabled, className, onSubmit }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const [shown, setShown] = useState(false);

  const businessUser = useSelector(checkIsBusinessUser);

  const hasItems = !!ids.length;

  const handleToReviewClick = useCallback(() => {
    setShown(false);
    onSubmit();
  }, [onSubmit]);

  useEffect(() => {
    setShown(hasItems);
  }, [hasItems]);

  return (
    <Popup
      shown={shown}
      className={classNames(
        Css.documentsPopup,
        Css.approve,
        className
      )}>
      <Card className={Css.card}>
        <span className={Css.icon}>
          <Icons.CheckCircle weight="bold" />
        </span>
        <span className={Css.text}>
          {Utils.replaceTextVars(
            messages.documentsReadyToApprove,
            { documentsCount: ids.length }
          )}
        </span>
        <Button
          outline
          disabled={disabled}
          className={Css.button}
          icon={Icons.PaperPlaneTilt}
          onClick={handleToReviewClick}>
          {!Utils.checkIsTouchDevice() && (businessUser ? uiTexts.moveAllToReview : uiTexts.approveAll)}
        </Button>
      </Card>
    </Popup>
  );
};

export default React.memo(DocumentsPopup);
