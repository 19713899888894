import Css from "./style.module.scss";

import ConfidencePercents from "nlib/common/ConfidencePercents";
import Constants from "const/Constants";
import DocumentContext from "contexts/DocumentContext";
import React, { useContext } from "react";
import Utils from "utils/Utils";

const Confidence = ({ fieldsNames = [], useMax = false }) => {
  const fieldsNamesArray = Array.isArray(fieldsNames) ? fieldsNames : [fieldsNames];

  const { recogniseData: { confidence = {} } } = useContext(DocumentContext);

  const fieldsValues = fieldsNamesArray.map((value) => Utils.getPropertyByPath(confidence, value) || 0);

  const confidenceValue = useMax ? Math.max(...fieldsValues) : Utils.getAverageNumber(fieldsValues);

  if (!Math.floor(confidenceValue * Constants.PERCENTS_MULTIPLIER)) return null;

  return (
    <div className={Css.confidence}>
      <ConfidencePercents reverseColors value={confidenceValue} />
    </div>
  );
};

export default React.memo(Confidence);
