import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser, getUserRestrictions } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import {
  getCurrentXeroOrganizationId,
  getCurrentZohoOrganizationId,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessItems,
  getSelectedBusinessLocations,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getDocumentsData } from "selectors/documents";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Card from "nlib/common/Card";
import ChangeStatusSelector from "nlib/common/ChangeStatusSelector";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "nlib/pages/DocumentsPage/DocumentsStatuses";
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import Select from "nlib/ui/Select";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";
import SelectClassInput from "nlib/common/SelectClassInput";
import SelectContactInput from "nlib/common/SelectContactInput";
import SelectItemInput from "nlib/common/SelectItemInput";
import SelectLocationInput from "nlib/common/SelectLocationInput";
import SelectPaymentAccount from "nlib/common/SelectPaymentAccount";
import SelectProjectInput from "nlib/common/SelectProjectInput";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";

const { TO_REVIEW, TO_REPORT, EXPORTED } = DataConstants.STATUSES;

const MODES = {
  CATEGORY: "category",
  CLASS: "class",
  ITEM: "item",
  LOCATION: "location",
  PAYEE: "address",
  PAYMENT_ACCOUNT: "serviceAccountId",
  PROJECT: "project",
  TAX_RATE: "taxRate"
};

const EntityControl = ({ mode, onChange, component: Component, ...rest }) => {
  const handleChange = useCallback((value) => {
    onChange(value, mode);
  }, [mode, onChange]);

  const props = useMemo(() => {
    return {
      onChange: handleChange,
      ...rest
    };
  }, [handleChange, rest]);

  switch (mode) {
    case MODES.CATEGORY: return <SelectCategoryInput {...props} />;
    case MODES.CLASS: return <SelectClassInput {...props} />;
    case MODES.ITEM: return <SelectItemInput {...props} />;
    case MODES.LOCATION: return <SelectLocationInput {...props} />;
    case MODES.PAYEE: return <SelectContactInput useWidget {...props} />;
    case MODES.PAYMENT_ACCOUNT: return <SelectPaymentAccount {...props} />;
    case MODES.PROJECT: return <SelectProjectInput {...props} />;
    case MODES.TAX_RATE: return <SelectTaxRateInput {...props} />;
    default:
      return null;
  }
};

const BulkActions = (props) => {
  const {
    disabled,
    selectedDocuments,
    onCancel,
    onEdit
  } = props;

  const rootRef = useRef();

  const controlDivRef = useRef();

  const [{ status: statusFilter }] = useEnvVars();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const { countryCode } = useSelector(getActiveOrganization);

  const userRestrictions = useSelector(getUserRestrictions);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const businessUser = useSelector(checkIsBusinessUser);

  const {
    settings: {
      allowClientPayeeSelection,
      allowClientCategorySelection,
      allowClientClassSelection,
      allowClientLocationSelection,
      allowClientProjectSelection,
      allowClientTaxRateSelection
    } = {},
    extraData: {
      integrationService
    } = {}
  } = useSelector(getSelectedBusinessData);

  const items = useSelector(getSelectedBusinessItems);

  const classes = useSelector(getSelectedBusinessClasses);

  const locations = useSelector(getSelectedBusinessLocations);

  const projects = useSelector(getSelectedBusinessProjects);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const documentsData = useSelector(getDocumentsData);

  const czCountry = countryCode === Countries.CZ;

  const usCountry = countryCode === Countries.US;

  const selectEntityOptions = useMemo(() => {
    return [
      (!businessUser || allowClientCategorySelection) && { value: MODES.CATEGORY, label: uiTexts.category },
      (!businessUser || allowClientCategorySelection) && items.length && { value: MODES.ITEM, label: uiTexts.item },
      (!businessUser || allowClientClassSelection) && classes.length && { value: MODES.CLASS, label: uiTexts.class },
      (!businessUser || allowClientLocationSelection) && locations.length && { value: MODES.LOCATION, label: uiTexts.location },
      (!businessUser || allowClientProjectSelection) && projects.length && {
        value: MODES.PROJECT,
        label: [
          projects.some(({ customer }) => !customer) && uiTexts.project,
          projects.some(({ customer }) => customer) && uiTexts.customer
        ].filter(Boolean).join("/")
      },
      !usCountry && (!businessUser || allowClientTaxRateSelection) && taxRates.length
        && { value: MODES.TAX_RATE, label: uiTexts.taxRate },
      (xeroBusiness || zohoBusiness) && { value: MODES.PAYMENT_ACCOUNT, label: uiTexts.paidFromTo }
    ].filter(Boolean);
  }, [
    businessUser,
    usCountry,
    allowClientCategorySelection,
    allowClientClassSelection,
    allowClientLocationSelection,
    allowClientProjectSelection,
    allowClientTaxRateSelection,
    classes.length,
    items.length,
    locations.length,
    taxRates.length,
    projects,
    uiTexts,
    xeroBusiness,
    zohoBusiness
  ]);

  const [entityMode, setEntityMode] = useState(selectEntityOptions.length ? selectEntityOptions[0].value : null);

  const [state, setState] = useState({});

  const bulkEditActionsDisabled = statusFilter && statusFilter !== TO_REVIEW;

  const disableEditButton = ![
    state[MODES.PAYEE]?.id,
    state[MODES.CATEGORY]?.code,
    state[MODES.PAYMENT_ACCOUNT],
    state[entityMode]?.id
  ].some(Boolean);

  const entityValue = useMemo(() => state[entityMode] || {}, [state, entityMode]);

  const statusesList = useMemo(() => {
    const systemSpecificExportLabelId = integrationService && {
      xero: "atXero",
      quickBooks: "atQuickBooks"
    }[integrationService];

    return Object.values(DocumentsStatuses.getStatusData(undefined, countryCode)).map((statusData) => {
      return systemSpecificExportLabelId && uiTexts[systemSpecificExportLabelId] && statusData.value === EXPORTED
        ? { ...statusData, labelLangId: systemSpecificExportLabelId }
        : statusData;
    });
  }, [countryCode, integrationService, uiTexts]);

  const handleDeleteClick = useCallback(async() => {
    const result = await showCommonModal({
      text: Utils.replaceTextVars(messages.bulkDocumentsDeleteConfirm, {
        documentsCount: selectedDocuments.length
      }),
      confirm: true
    });

    if (result) onEdit({ remove: !!result });
  }, [selectedDocuments.length, messages, showCommonModal, onEdit]);

  const handleInputChange = useCallback((value, name) => {
    setState(({ [MODES.PAYEE]: payee, ...rest }) => {
      switch (name) {
        case MODES.PAYEE:
          return { ...rest, [name]: value };
        case MODES.CATEGORY:
          return {
            [MODES.PAYEE]: payee,
            [name]: value,
            tags: [value.name]
          };
        case MODES.ITEM:
          return {
            [MODES.PAYEE]: payee,
            [name]: value,
            tags: [value.name]
          };
        default:
          return { [MODES.PAYEE]: payee, [name]: value };
      }
    });
  }, []);

  const handleSaveButtonClick = useCallback(() => {
    onEdit(state);
  }, [state, onEdit]);

  const handleSelectEntityModeChange = useCallback((value) => {
    setEntityMode(value);
    setState(({ [MODES.PAYEE]: payee }) => ({ [MODES.PAYEE]: payee }));
  }, []);

  const handleChangeStatusSelectorChange = useCallback(async(value) => {
    const changeExportedStatusConfirm = selectedDocuments.some((documentId) => {
      return Utils.arrayFindById(documentsData, documentId, {}).status === EXPORTED;
    });

    if (changeExportedStatusConfirm) {
      const modalResult = await showCommonModal({ text: messages.changeExportedStatusConfirm, confirm: true });

      if (!modalResult) return;
    }

    onEdit({ status: value });
  }, [documentsData, messages, onEdit, selectedDocuments, showCommonModal]);

  useLayoutEffect(() => {
    const scrollContainer = document.querySelector(".scroll-container");

    let shift;

    if (scrollContainer && rootRef.current && controlDivRef.current) {
      const computedStyle = window.getComputedStyle(rootRef.current);

      const boundingRect = rootRef.current.getBoundingClientRect();

      const marginTop = parseInt(computedStyle.marginTop, 10);

      const marginBottom = parseInt(computedStyle.marginBottom, 10);

      if (boundingRect.top === (controlDivRef.current.getBoundingClientRect().top + marginTop)) {
        shift = boundingRect.height + marginTop + marginBottom;
        scrollContainer.scrollTop += shift;

        return () => {
          scrollContainer.scrollTop -= shift;
        };
      }
    }

    return () => {};
  }, []);

  return (
    <>
      <div ref={controlDivRef} />
      <Card ref={rootRef} className={Css.bulkActions} disabled={disabled}>
        <div className={Css.text}>
          {`${uiTexts.selected}: ${selectedDocuments.length}`}
        </div>
        {!bulkEditActionsDisabled && !czCountry && (
          <>
            {(!businessUser || allowClientPayeeSelection) && (
              <EntityControl
                validate
                name={MODES.PAYEE}
                mode={MODES.PAYEE}
                value={state[MODES.PAYEE]}
                className={Css.input}
                onChange={handleInputChange} />
            )}
            {!!selectEntityOptions.length && (
              <>
                <div className={Css.entityContainer}>
                  {selectEntityOptions.length > 1 && (
                    <Select
                      className={Css.selectEntityMode}
                      value={entityMode}
                      options={selectEntityOptions}
                      onChange={handleSelectEntityModeChange} />
                  )}
                  <EntityControl
                    validate
                    name={entityMode}
                    mode={entityMode}
                    value={entityValue}
                    className={Css.input}
                    onChange={handleInputChange} />
                </div>
              </>
            )}
            <Button
              large primary
              icon={Icons.FloppyDisk}
              disabled={disableEditButton}
              onClick={handleSaveButtonClick}>
              {uiTexts.save}
            </Button>
          </>
        )}
        <div className={Css.buttons}>
          <Button
            large primary light
            icon={Icons.X}
            onClick={onCancel}>
            {uiTexts.cancel}
          </Button>
          {!businessUser && (
            <ChangeStatusSelector
              statusesList={statusesList}
              excludedStatuses={[
                statusFilter,
                userRestrictions.documentsUpdate && TO_REVIEW,
                !czCountry && TO_REPORT
              ]}
              onChange={handleChangeStatusSelectorChange} />
          )}
          <Button
            large danger
            icon={Icons.Trash}
            disabled={userRestrictions.documentsDelete}
            onClick={handleDeleteClick}>
            {uiTexts.delete}
          </Button>
        </div>
      </Card>
    </>
  );
};

export default React.memo(BulkActions);
