import EditDocumentContent from "nlib/common/EditDocumentContent";
import FormContext from "contexts/FormContext";
import React, { useCallback, useContext } from "react";

const EditForm = ({ disabled, documentData, documentFrozen, openPairedTransactionsWindow }) => {
  const { values, setState } = useContext(FormContext);

  const handleDocumentChange = useCallback((update) => {
    setState((state) => {
      const newValues = update(state.values);

      return { ...state, values: { ...state.values, ...newValues } };
    });
  }, [setState]);

  return (
    <EditDocumentContent
      disabled={disabled}
      recogniseData={documentData.recogniseData}
      documentData={documentData}
      documentFrozen={documentFrozen}
      state={values}
      onChange={handleDocumentChange}
      onPairedTransactionClick={openPairedTransactionsWindow} />
  );
};

export default React.memo(EditForm);
