import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getDocumentsStats } from "selectors/documents";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "nlib/pages/DocumentsPage/DocumentsStatuses";
import React, { useMemo } from "react";
import StatusFilter from "nlib/common/StatusFilter";

const { STATUSES: { EXPORTED, NEED_REACTION, TO_REVIEW, TO_RECONCILE, TO_REPORT, EXCLUDED, TO_EXTRACT } } = DataConstants;

const ICONS = {
  [TO_RECONCILE]: Icons.Question,
  [NEED_REACTION]: Icons.WarningCircle,
  [TO_REVIEW]: Icons.Eye,
  [TO_REPORT]: Icons.CheckCircle,
  [EXPORTED]: Icons.ArrowSquareOut,
  [EXCLUDED]: Icons.MinusCircle,
  [TO_EXTRACT]: Icons.Cloud,
  [null]: Icons.ListDashes
};

const getIcon = (value) => ICONS[value];

const DocumentsStatusFilter = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const documentsStats = useSelector(getDocumentsStats);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const statusesList = useMemo(() => {
    const { extraData: { integrationService } = {} } = selectedBusinessData;

    const systemSpecificExportLabelId = integrationService && {
      xero: "atXero",
      quickBooks: "atQuickBooks"
    }[integrationService];

    const statusesData = businessUser ? DocumentsStatuses.getBusinessUserStatusData() : DocumentsStatuses.getStatusData();

    return Object.values(statusesData).map((statusData) => {
      return systemSpecificExportLabelId && uiTexts[systemSpecificExportLabelId] && statusData.value === EXPORTED
        ? { ...statusData, labelLangId: systemSpecificExportLabelId }
        : statusData;
    });
  }, [businessUser, selectedBusinessData, uiTexts]);

  return (
    <StatusFilter
      {...props}
      statusesList={statusesList}
      stats={documentsStats}
      defaultStatus={DataConstants.STATUSES.TO_REVIEW}
      getIcon={getIcon} />
  );
};

export default React.memo(DocumentsStatusFilter);
