import { Button } from "nlib/ui";
import { INPUT_FIELD_NAMES } from "nlib/common/ContactForm/constants";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ContactFormContext from "contexts/ContactFormContext";
import ContactsActions from "actions/ContactsActions";
import DataConstants from "const/DataConstants";
import React, { useCallback, useContext } from "react";

const ContactFormPrefillButton = ({ children, ...restProps }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const { disabled, readOnly, state, updateForm } = useContext(ContactFormContext);

  const prefillIdNumber = state[INPUT_FIELD_NAMES.PREFILL_ID_NUMBER];

  const handleButtonClick = useCallback(async() => {
    const prefillResult = await dispatch(ContactsActions.lookupContactData(prefillIdNumber));

    if (prefillResult) {
      updateForm({
        ...prefillResult,
        type: DataConstants.CONTACT_TYPES.LEGAL_PERSON,
        subType: (prefillResult.subType || "").toString(),
        countryCode: (prefillResult.countryCode || "").toString(),
        idNumber: (prefillResult.idNumber || "").toString(),
        vatId: (prefillResult.vatId || "").toString(),
        phone: (prefillResult.phone || "").toString(),
        zipCode: (prefillResult.zipCode || "").toString(),
        prefillIdNumber: ""
      });
    }
  }, [dispatch, prefillIdNumber, updateForm]);

  return (
    <Button
      placeholder={uiTexts.enterId}
      {...restProps}
      disabled={disabled || !prefillIdNumber}
      readOnly={readOnly}
      onClick={handleButtonClick}>
      {children || uiTexts.prefill}
    </Button>
  );
};

export default React.memo(ContactFormPrefillButton);
