import Css from "./style.module.scss";

import { AutoTooltip } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import { getSelectedBusinessData } from "selectors/businesses";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import InteractiveField from "./lib/InteractiveField";
import React from "react";

const { STATUSES } = DataConstants;

const INTERACTIVE_FIELD_ENABLED = false;

const CategoriesCell = ({ documentId, status, type, paymentType, lineItems, onEdit }) => {
  const {
    settings: {
      allowClientCategorySelection,
      recognizeLineItems
    } = {}
  } = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  if (!lineItems?.length) return Constants.EMPTY_PLACEHOLDER;

  if (
    !INTERACTIVE_FIELD_ENABLED
    || recognizeLineItems
    || status !== STATUSES.TO_REVIEW
    || (businessUser && !allowClientCategorySelection)) {
    const content = [...new Set((lineItems || []).map(({ category, item }) => {
      if (!item && !category) return null;

      return item ? item.name : category.name;
    }).filter(Boolean))].join(", ");

    return (
      <div className={Css.categoriesCell}>
        <AutoTooltip active={!!content}>
          {content || Constants.EMPTY_PLACEHOLDER}
        </AutoTooltip>
      </div>
    );
  }

  return (
    <div className={Css.categoriesCell}>
      <InteractiveField
        documentId={documentId}
        type={type}
        paymentType={paymentType}
        lineItems={lineItems}
        onEdit={onEdit} />
    </div>
  );
};

export default React.memo(CategoriesCell);
