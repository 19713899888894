import * as Icons from "@phosphor-icons/react";
import { getAccountsData } from "selectors/accounts";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import Select from "nlib/ui/Select";

const SelectPaymentAccount = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    readOnly,
    validate,
    placeholder = readOnly ? "" : uiTexts.selectPaymentAccount,
    ...restProps
  } = props;

  const accountsData = useSelector(getAccountsData);

  const {
    extraData: {
      xeroClassification,
      zohoClassification
    } = {}
  } = useSelector(getSelectedBusinessData);

  const classification = useMemo(() => {
    return xeroClassification || zohoClassification || [];
  }, [xeroClassification, zohoClassification]);

  const groups = useMemo(() => {
    return [
      { value: "a", label: uiTexts.bankAccounts },
      { value: "b", label: uiTexts.otherAccounts }
    ];
  }, [uiTexts]);

  const options = useMemo(() => {
    return [
      ...accountsData.map(({ name, extraData: { xeroAccountId, zohoAccountId } = {} }) => {
        return { value: xeroAccountId || zohoAccountId, group: "a", label: name };
      }),
      ...classification
        .filter(({ paymentsAllowed }) => paymentsAllowed)
        .map(({ accountId, displayName }) => {
          return { value: accountId, group: "b", label: displayName };
        })
    ];
  }, [accountsData, classification]);

  return (
    <Select
      {...restProps}
      groups={groups}
      options={options}
      iconBefore={Icons.CreditCard}
      placeholder={placeholder} />
  );
};

export default React.memo(SelectPaymentAccount);
