import Css from "./style.module.scss";

import Activity from "nlib/common/Activity";
import DataConstants from "const/DataConstants";
import React from "react";

const { COMMENT_TARGET_TYPES: { DOCUMENTS } } = DataConstants;

const ActivityTab = ({ disabled, documentData }) => {
  return (
    <div className={Css.activityTab}>
      <Activity
        showAllInitial
        disabled={disabled}
        itemId={documentData.id}
        type={DOCUMENTS}
        commentsCount={documentData.comments} />
    </div>
  );
};

export default React.memo(ActivityTab);
