import Css from "./style.module.scss";

import { getBlockRenderCoords } from "nlib/common/EditDocumentContent/utils";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const MARGIN = 8;

const checkIsInContainer = (element, container) => {
  if (!element || !container) return false;

  const elementRect = element.getBoundingClientRect();

  const containerRect = container.getBoundingClientRect();

  return elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom;
};

const ColoredBlock = (props) => {
  const {
    block,
    color,
    coloredBlock,
    scrollContainerRef,
    docPageHeights,
    docRenderWidth,
    autoScroll = false
  } = props;

  const { setLocalState, localState: { activeField, hoveredField } } = useDocumentContext();

  const blockRef = useRef(null);

  const [bgColor, setBgColor] = useState(color);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      blockRef.current
      && (activeField || hoveredField)
      && autoScroll
      && scrollContainerRef.current
      && !checkIsInContainer(blockRef.current, scrollContainerRef.current)
    ) {
      const { top: containerTop, bottom: containerBottom } = scrollContainerRef.current.getBoundingClientRect();

      const { top: blockTop, bottom: blockBottom } = blockRef.current.getBoundingClientRect();

      const scrollDelta = Math.min(containerBottom - blockBottom - MARGIN, 0) || Math.max(containerTop - blockTop + MARGIN, 0);

      const { scrollTop: initialScroll } = scrollContainerRef.current;

      return Utils.animate((progress) => {
        scrollContainerRef.current.scrollTop = initialScroll - (scrollDelta * progress);
      });
    }
  }, [activeField, autoScroll, scrollContainerRef, hoveredField]);

  useEffect(() => {
    setBgColor(`${color}33`);
  }, [color]);

  const coords = useMemo(() => {
    return getBlockRenderCoords(
      block.boundingBox,
      docPageHeights,
      docRenderWidth
    );
  }, [block.boundingBox, docPageHeights, docRenderWidth]);

  const style = useMemo(() => {
    return {
      ...coords,
      backgroundColor: bgColor,
      border: `1px solid ${color}`
    };
  }, [bgColor, color, coords]);

  const { sourceFieldId } = coloredBlock || {};

  const handleMouseEnter = useCallback(() => {
    setLocalState({ hoveredField: { id: sourceFieldId } });
  }, [setLocalState, sourceFieldId]);

  const handleMouseLeave = useCallback(() => {
    setLocalState({ hoveredField: null });
  }, [setLocalState]);

  return (
    <div
      data-block={sourceFieldId}
      style={style}
      className={Css.coloredBlock}
      onMouseEnter={sourceFieldId ? handleMouseEnter : undefined}
      onMouseLeave={sourceFieldId ? handleMouseLeave : undefined}
      ref={blockRef} />
  );
};

export default ColoredBlock;
