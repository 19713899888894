import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FieldContainer from "../FieldContainer";
import MoneyInput from "nlib/common/MoneyInput";
import React, { useCallback } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const AmountField = (props) => {
  const id = "amount";

  const { uiTexts } = useSelector(getTextsData);

  const {
    documentState: { [id]: value, currency, amountBase, amountVat },
    setDocumentState
  } = useDocumentContext();

  const invalid = Utils.toMoneyNumber((value || 0), true, "....")
    !== Utils.toMoneyNumber((amountBase || 0) + (amountVat || 0), true, "....");

  const handleChange = useCallback((val) => {
    setDocumentState({ [id]: val });
  }, [setDocumentState]);

  return (
    <FieldContainer
      type="number"
      id={id}
      confidenceFields={["amount"]}
      label={uiTexts.total}
      invalid={invalid}
      value={value}
      placeholder={uiTexts.enterTotal}
      displayValue={Utils.toMoneyString(value || 0, currency)}
      component={MoneyInput}
      onChange={handleChange}
      {...props} />
  );
};

export default React.memo(AmountField);
