import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import AutoTooltip from "nlib/ui/AutoTooltip";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";

const { STATUSES: { TO_EXTRACT } } = DataConstants;

const DocumentIdCell = ({ documentData }) => {
  const {
    originalDocumentId,
    status,
    duplicatedDocumentsIds,
    attachment: { key: attachmentKey } = {}
  } = documentData;

  const extractionStatus = status === TO_EXTRACT;

  const hasDuplicates = !!(Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length);

  return (
    <div className={Css.documentIdCell}>
      <AutoTooltip className={Css.documentId} active={!!originalDocumentId}>
        {extractionStatus
          ? Constants.EMPTY_PLACEHOLDER
          : (
            <>
              {hasDuplicates
                ? <Icons.Copy className={Css.warning} />
                : <Icons.FileText className={attachmentKey ? "" : Css.negative} />}
              <span>{originalDocumentId || Constants.EMPTY_PLACEHOLDER}</span>
            </>
          )}
      </AutoTooltip>
    </div>
  );
};

export default React.memo(DocumentIdCell);
