import Css from "nlib/pages/DocumentsPage/lib/EditDocument/lib/OldEditForm/lib/DocumentTab/style.module.scss";

import * as Icons from "@phosphor-icons/react";
import * as Yup from "yup";
import { checkContactsFetching } from "selectors/contacts";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import ContactForm, {
  ContactFormCityInput,
  ContactFormEmailInput,
  ContactFormPhoneInput,
  ContactFormSelectCountry,
  ContactFormSelectState,
  ContactFormStreetInput,
  ContactFormTypeSelect,
  ContactFormZipCodeInput
} from "nlib/common/ContactForm";
import ContactFormIdInput from "nlib/common/ContactForm/lib/ContactFormIdInput";
import ContactFormPrefillButton from "nlib/common/ContactForm/lib/ContactFormPrefillButton";
import ContactFormPrefillInput from "nlib/common/ContactForm/lib/ContactFormPrefillInput";
import ContactFormTaxIdInput from "nlib/common/ContactForm/lib/ContactFormTaxIdInput";
import DataConstants from "const/DataConstants";
import FormContext from "contexts/FormContext";
import React, { useCallback, useContext, useMemo } from "react";

const { CONTACT_SUB_TYPES: { VENDOR, CUSTOMER } } = DataConstants;

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().trim().required(),
  type: Yup.string().required(),
  countryCode: Yup.string(),
  idNumber: Yup.string().trim(),
  vatId: Yup.string().trim(),
  phone: Yup.string().trim(),
  email: Yup.string().trim().email(),
  state: Yup.string().trim(),
  city: Yup.string().trim(),
  street: Yup.string().trim(),
  zipCode: Yup.string().trim()
});

const ContactFormColumn = ({ documentFrozen, onSubmit: handleFormSubmit, onUnlink, onCancel }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const {
    settings: { allowClientPayeeSelection } = {}
  } = useSelector(getSelectedBusinessData);

  const fetchingContacts = useSelector(checkContactsFetching);

  const { values: { paymentType, vendorId, address = {}, currency }, onChange } = useContext(FormContext);

  const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

  const prefillData = useMemo(() => {
    return {
      currency,
      type: "",
      subType: paymentType ? (buyPaymentType ? VENDOR : CUSTOMER) : "",
      ...(vendorId ? {} : address)
    };
  }, [currency, paymentType, buyPaymentType, vendorId, address]);

  const handleFormChange = useCallback((newState, action) => {
    if (action.type === "update") {
      onChange({ name: "address", value: newState });
    }
  }, [onChange]);

  return (
    <ContactForm
      className={Css.contactForm}
      vendorId={vendorId}
      currency={currency}
      key={`${currency}:${paymentType}`}
      prefillData={prefillData}
      readOnly={!allowClientPayeeSelection}
      disabled={documentFrozen || fetchingContacts || !paymentType}
      onChange={handleFormChange}
      onSubmit={handleFormSubmit}>
      {({ disabled, state, onSubmit }) => {
        const { name = state.name } = address;

        const formValid = VALIDATION_SCHEMA.isValidSync({ ...state, name });

        return (
          <>
            <div className={Css.wrapper}>
              {!vendorId && (
                <>
                  <div className={Css.description}>
                    {`${messages.prefillContactData}:`}
                  </div>
                  <div className={Css.row}>
                    <div className={Css.col}>
                      <ContactFormPrefillInput />
                    </div>
                    <div className={Css.col}>
                      <ContactFormPrefillButton
                        large outline block
                        className={Css.prefillButton}>
                        <Icons.ClipboardText />
                        <span>{uiTexts.prefill}</span>
                      </ContactFormPrefillButton>
                    </div>
                  </div>
                  <div className={Css.description}>
                    {`${messages.enterDataManually}:`}
                  </div>
                </>
              )}
              <div className={Css.label}>{uiTexts.type}</div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <ContactFormTypeSelect />
                </div>
              </div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <div className={Css.label}>{uiTexts.businessId}</div>
                  <ContactFormIdInput />
                </div>
                <div className={Css.col}>
                  <div className={Css.label}>{uiTexts.vatId}</div>
                  <ContactFormTaxIdInput />
                </div>
              </div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <div className={Css.label}>{uiTexts.country}</div>
                  <ContactFormSelectCountry />
                </div>
                <div className={Css.col}>
                  <div className={Css.label}>{uiTexts.state}</div>
                  <ContactFormSelectState />
                </div>
              </div>
              <div className={Css.label}>{uiTexts.city}</div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <ContactFormCityInput />
                </div>
              </div>
              <div className={Css.row}>
                <div className={`${Css.col} ${Css.colStreet}`}>
                  <div className={Css.label}>{uiTexts.street}</div>
                  <ContactFormStreetInput />
                </div>
                <div className={Css.col}>
                  <div className={Css.label}>{uiTexts.zipCode}</div>
                  <ContactFormZipCodeInput />
                </div>
              </div>
              <div className={Css.label}>{uiTexts.phone}</div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <ContactFormPhoneInput />
                </div>
              </div>
              <div className={Css.label}>{uiTexts.email}</div>
              <div className={Css.row}>
                <div className={Css.col}>
                  <ContactFormEmailInput />
                </div>
              </div>
            </div>
            {allowClientPayeeSelection && (
              <div className={Css.footer}>
                <Button
                  large outline
                  tagName="div"
                  className={Css.button}
                  onClick={onCancel}>
                  {uiTexts.cancel}
                </Button>
                {vendorId
                  ? (
                    <Button
                      danger large
                      className={Css.button}
                      disabled={disabled}
                      onClick={onUnlink}>
                      {uiTexts.unlinkContact}
                    </Button>
                  )
                  : (
                    <Button
                      primary large
                      tagName="div"
                      className={Css.button}
                      disabled={disabled || !formValid}
                      onClick={onSubmit}>
                      {uiTexts.addContact}
                    </Button>
                  )}
              </div>
            )}
          </>
        );
      }}
    </ContactForm>
  );
};

export default React.memo(ContactFormColumn);
