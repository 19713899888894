import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FieldContainer from "../FieldContainer";
import React, { useCallback } from "react";
import useDocumentContext from "hooks/useDocumentContext";

const MemoField = (props) => {
  const id = "privateNote";

  const { uiTexts } = useSelector(getTextsData);

  const {
    documentState: { [id]: value },
    setDocumentState
  } = useDocumentContext();

  const handleChange = useCallback((newValue) => {
    setDocumentState({ [id]: newValue });
  }, [setDocumentState]);

  return (
    <FieldContainer
      id={id}
      label={uiTexts.memo}
      placeholder={uiTexts.enterDocumentMemo}
      value={value}
      displayValue={value}
      onChange={handleChange}
      {...props} />
  );
};

export default React.memo(MemoField);
