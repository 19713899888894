import Css from "./style.module.scss";

import {
  getCurrentXeroOrganizationId,
  getSelectedBusinessCategories,
  getSelectedBusinessData,
  getSelectedBusinessItems
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoCompleteInput from "nlib/ui/AutoCompleteInput";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";

const { DOCUMENT_PAYMENT_TYPES, DOCUMENT_TYPES: { SALES_RECEIPT } } = DataConstants;

const GROUP_TO_THEME_MAP = {
  category: "primary",
  item: "positive"
};

const SUB_CATEGORY_DELIMITER = {
  [IntegrationServices.QUICK_BOOKS.value]: ":",
  [IntegrationServices.QUICK_BOOKS_DESKTOP.value]: ":"
};

const CODE_REGEX = /^[\d\w]+:\s/;

const SelectCategoryOrItem = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    value = {},
    onChange,
    validate,
    type,
    paymentType,
    onAutoComplete,
    ...restProps
  } = props;

  const { extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const [showAll, setShowAll] = useState(false);

  const { name: inputValue = "" } = value;

  const categories = useSelector(getSelectedBusinessCategories);

  const items = useSelector(getSelectedBusinessItems);

  const filteredItems = useMemo(() => {
    if (!xeroBusiness) return items;

    return items.filter(({ salesItem, purchaseItem }) => {
      return !paymentType || (paymentType === DOCUMENT_PAYMENT_TYPES.BUY ? purchaseItem : salesItem);
    });
  }, [items, paymentType, xeroBusiness]);

  const categoriesList = useMemo(() => {
    if (type === SALES_RECEIPT) return [];

    return [...categories]
      .sort((
        { displayName: displayNameA },
        { displayName: displayNameB }
      ) => {
        displayNameA = displayNameA.slice(CODE_REGEX, "");

        displayNameB = displayNameB.slice(CODE_REGEX, "");

        return displayNameA.localeCompare(displayNameB);
      })
      .map(({ code, name, displayName = "" }) => {
        return { value: code, label: name, displayName, type: "category" };
      });
  }, [categories, type]);

  const itemsList = useMemo(() => {
    return [...filteredItems]
      .map(({ id, name, displayName = "" }) => {
        return { value: id, label: name, displayName, type: "item" };
      });
  }, [filteredItems]);

  const summaryList = useMemo(() => {
    return [
      ...categoriesList,
      ...itemsList
    ];
  }, [categoriesList, itemsList]);

  const filteredList = useMemo(() => {
    if (!inputValue || showAll) return summaryList;

    const localValue = inputValue.trim().toLowerCase();

    return summaryList.filter((item) => {
      if (!item.label) return false;

      const itemValue = (item.displayName || item.label).trim().toLowerCase();

      if (itemValue === localValue || itemValue.includes(localValue)) {
        return true;
      }

      const similarityCheck = Utils.checkSimilarityBy(
        { value: inputValue },
        { value: item.displayName },
        Constants.DATA_SIMILARITY_COEFFICIENT
      );

      if (similarityCheck) return true;

      const delimeter = SUB_CATEGORY_DELIMITER[integrationService];

      const startsWith = `${item.label}${delimeter}`;

      return similarityCheck || summaryList
        .filter(({ label }) => label.startsWith(startsWith))
        .some(({ label }) => {
          const labelLowered = label.toLowerCase();

          return labelLowered === localValue || labelLowered.includes(localValue);
        });
    });
  }, [inputValue, showAll, summaryList, integrationService]);

  const renderLabel = useCallback(({ label, displayName, type: optionType }) => {
    const delimiter = SUB_CATEGORY_DELIMITER[integrationService];

    const splitted = displayName.replace(CODE_REGEX, "").split(delimiter);

    return (
      <div className={Css.listItem} title={label}>
        <span className={Css.name}>
          {!!splitted.length && (
            [...new Array(splitted.length)].map((skip, index) => {
              return !!index && <span key={String(index + 1)} className={Css.indent} />;
            })
          )}
          <span>{label}</span>
        </span>
        <Badge className={Css.badge} theme={GROUP_TO_THEME_MAP[optionType]}>
          {uiTexts[optionType]}
        </Badge>
      </div>
    );
  }, [integrationService, uiTexts]);

  const handleChange = useCallback((newName, event) => {
    onChange({ name: newName }, event);
  }, [onChange]);

  const handleAutoComplete = useCallback((selected, event) => {
    if (onAutoComplete) onAutoComplete(selected, event);

    const found = selected.type === "category"
      ? Utils.arrayFind(categories, "code", selected.value)
      : Utils.arrayFind(items, "id", selected.value);

    onChange(found || {}, event);
  }, [categories, items, onChange, onAutoComplete]);

  return (
    <AutoCompleteInput
      {...(validate ? {
        valid: !!(value.displayName && value.code),
        invalid: !!(value.displayName && !value.code)
      } : {})}
      {...restProps}
      value={inputValue}
      placeholder={categoriesList.length && itemsList.length
        ? `${uiTexts.selectCategory}/${uiTexts.item.toLowerCase()}`
        : (itemsList.length ? uiTexts.selectItem : uiTexts.selectCategory)}
      showAll={showAll}
      renderLabel={renderLabel}
      items={summaryList}
      filteredItems={filteredList}
      onSetShowAll={setShowAll}
      onChange={handleChange}
      onAutoComplete={handleAutoComplete} />
  );
};

export default React.memo(SelectCategoryOrItem);
