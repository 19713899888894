import ParentCss from "../../style.module.scss";

import { Checkbox } from "nlib/ui";
import { DeleteCell } from "../DeleteCell";
import { checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import {
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getCurrentZohoOrganizationId,
  getSelectedBusinessCategories,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import CategoryOrItemTableCell from "./lib/CategoryOrItemTableCell";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useRef } from "react";
import SelectClassInput from "nlib/common/SelectClassInput";
import SelectItemInput from "nlib/common/SelectItemInput";
import SelectProjectInput from "nlib/common/SelectProjectInput";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";
import TableCell from "./lib/TableCell";
import Utils from "utils/Utils";
import classNames from "classnames";
import useDocumentContext from "hooks/useDocumentContext";

const LineItem = (props) => {
  const {
    selected,
    index,
    lineItem,
    paymentType,
    deleteEnable,
    enableClasses,
    enableProjects,
    xeroFilteredItems,
    categoryOrItemsSelectOptionsLength,
    onSelect
  } = props;

  const {
    uuid,
    newLineItem,
    description,
    quantity,
    amountBase,
    vatRate,
    class: classValue,
    project,
    item,
    category,
    taxRate
  } = lineItem;

  const rootRef = useRef();

  const { uiTexts } = useSelector(getTextsData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const businessUser = useSelector(checkIsBusinessUser);

  const { countryCode } = useSelector(getActiveOrganization);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const categories = useSelector(getSelectedBusinessCategories);

  const projects = useSelector(getSelectedBusinessProjects);

  const classes = useSelector(getSelectedBusinessClasses);

  const {
    settings: { allowClientTaxRateSelection } = {}
  } = useSelector(getSelectedBusinessData);

  const usCountry = countryCode === Countries.US;

  const {
    documentState: { lineItems },
    documentFrozen,
    updateLineItem,
    setDocumentState
  } = useDocumentContext();

  const precision = quickBooksBusiness ? "......." : (zohoBusiness ? "......" : "....");

  const invalidAmountBase = !amountBase && (amountBase !== 0);

  const invalidVatRate = (!vatRate && vatRate !== 0) || +vatRate < 0;

  const invalidClass = !!(classValue?.name && !classValue?.id);

  const invalidItem = !!(item?.name && !item?.id);

  const invalidProject = !!(project?.name && !project?.id);

  const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

  const invalidTaxRate = !businessUser && quickBooksBusiness && !usCountry
    ? !taxRate?.id && lineItems.some((someItem) => someItem.taxRate?.id)
    : !!(taxRate?.name && !taxRate?.id);

  const handleSelectRowChange = useCallback(() => {
    onSelect(uuid, selected);
  }, [onSelect, selected, uuid]);

  const handleOnDeleteRow = useCallback(() => {
    setDocumentState({
      lineItems: lineItems.filter((data) => data.uuid !== uuid)
    });
  }, [lineItems, setDocumentState, uuid]);

  const handleItemChange = useCallback((name, newValue) => {
    if (newValue) {
      const preselectCategoryCode = paymentType && newValue[buyPaymentType ? "purchaseCategoryCode" : "salesCategoryCode"];

      const preselectTaxRateId = paymentType && newValue[buyPaymentType ? "purchaseTaxType" : "salesTaxType"];

      const preselectCategory = !category?.code && preselectCategoryCode
        && Utils.arrayFind(categories, "code", preselectCategoryCode);

      const preselectTaxRate = !taxRate?.id && preselectTaxRateId && Utils.arrayFindById(taxRates, preselectTaxRateId);

      const preselectClass = !classValue?.id && newValue.classId && Utils.arrayFindById(classes, newValue.classId);

      updateLineItem(uuid, "item", newValue);
      if (preselectCategory) updateLineItem(uuid, "category", preselectCategory);
      if (preselectTaxRate) updateLineItem(uuid, "taxRate", preselectTaxRate);
      if (preselectClass) updateLineItem(uuid, "class", preselectClass);
    } else {
      updateLineItem(uuid, "item", newValue);
    }
  }, [
    buyPaymentType,
    categories,
    category?.code,
    classValue?.id,
    classes,
    paymentType,
    updateLineItem,
    taxRate?.id,
    taxRates,
    uuid
  ]);

  const handleChange = useCallback((fieldName, value) => {
    switch (fieldName) {
      case "quantity":
        updateLineItem(uuid, fieldName, value ? Utils.toMoneyNumber(value, true, precision) : undefined);
        break;

      case "amountBase":
        updateLineItem(uuid, fieldName, value && Utils.toMoneyNumber(value));
        break;

      default:
        updateLineItem(uuid, fieldName, value);
        break;
    }
  }, [uuid, updateLineItem, precision]);

  useEffect(() => {
    if (newLineItem) rootRef.current.scrollIntoView();
  }, [newLineItem]);

  return (
    <div className={ParentCss.row} ref={rootRef}>
      <div className={classNames(ParentCss.cell, ParentCss.cellNumber)}>
        <Checkbox
          disabled={documentFrozen}
          data-id={uuid}
          checked={selected}
          onChange={handleSelectRowChange}>
          <span>{`#${index + 1}`}</span>
        </Checkbox>
      </div>
      <TableCell
        name="description"
        uuid={uuid}
        invalid={!description}
        value={description}
        displayValue={description}
        placeholder={uiTexts.enterDescription}
        className={ParentCss.cellDescription}
        onChange={handleChange} />
      <TableCell
        name="quantity"
        uuid={uuid}
        type="number"
        step="any"
        min={0}
        value={quantity || ""}
        displayValue={quantity}
        className={ParentCss.cellQuantity}
        onChange={handleChange} />
      <TableCell
        name="amountBase"
        uuid={uuid}
        type="number"
        step="any"
        placeholder={uiTexts.enterSubtotal}
        invalid={invalidAmountBase}
        value={amountBase}
        displayValue={amountBase}
        className={ParentCss.cellAmount}
        onChange={handleChange} />
      {xeroBusiness || zohoBusiness || (quickBooksBusiness && !usCountry)
        ? ((!businessUser || allowClientTaxRateSelection) && !!taxRates.length && (
          <TableCell
            portal
            selectable={false}
            name="taxRate"
            uuid={uuid}
            value={taxRate}
            invalid={invalidTaxRate}
            placeholder={uiTexts.selectTaxRate}
            displayValue={taxRate?.name}
            className={ParentCss.cellTaxRate}
            inputComponent={SelectTaxRateInput}
            onChange={handleChange} />
        ))
        : (
          <TableCell
            name="vatRate"
            uuid={uuid}
            type="number"
            step="any"
            min={0}
            placeholder={`${uiTexts.enterVat} (%)`}
            invalid={invalidVatRate}
            value={vatRate}
            displayValue={vatRate}
            className={ParentCss.cellVat}
            onChange={handleChange} />
        )}
      {!!xeroFilteredItems.length && (
        <TableCell
          portal
          validate
          name="item"
          uuid={uuid}
          invalid={invalidItem}
          value={item}
          paymentType={paymentType}
          items={xeroFilteredItems}
          displayValue={item?.name}
          placeholder={uiTexts.selectItem}
          inputComponent={SelectItemInput}
          className={ParentCss.cellItems}
          onChange={handleItemChange} />
      )}
      {!!categoryOrItemsSelectOptionsLength && (
        <CategoryOrItemTableCell
          uuid={uuid}
          item={!xeroBusiness && item}
          category={category}
          className={classNames(ParentCss.cellCategory, categoryOrItemsSelectOptionsLength > 1 && ParentCss.extended)}
          onChange={handleChange} />
      )}

      {enableClasses && (
        <TableCell
          portal
          validate
          name="class"
          uuid={uuid}
          invalid={invalidClass}
          value={classValue}
          displayValue={classValue?.name}
          placeholder={uiTexts.selectClass}
          inputComponent={SelectClassInput}
          className={ParentCss.cellClass}
          onChange={handleChange} />
      )}
      {enableProjects && (
        <TableCell
          portal
          validate
          name="project"
          uuid={uuid}
          invalid={invalidProject}
          value={project}
          placeholder={projects.some(({ customer }) => customer)
            ? (projects.some(({ customer }) => !customer)
              ? uiTexts.selectProjectOrCustomer : uiTexts.selectCustomer)
            : uiTexts.selectProject}
          displayValue={project?.name}
          inputComponent={SelectProjectInput}
          className={ParentCss.cellProjects}
          onChange={handleChange} />
      )}
      <DeleteCell
        id={lineItem.uuid}
        disabled={!deleteEnable || documentFrozen}
        className={classNames(ParentCss.cell, ParentCss.removeCell)}
        onDelete={handleOnDeleteRow} />
    </div>
  );
};

export default React.memo(LineItem);
