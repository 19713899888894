import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Avatar, Tooltip } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback, useState } from "react";
import classNames from "classnames";
import moment from "moment";

const SOURCE_TYPE_EMAIL = "email";

const UploadedByCell = ({ sourceType, subject, text, createdBy, createdAt }) => {
  const { fullName, email } = createdBy || {};

  const { uiTexts } = useSelector(getTextsData);

  const createdAtText = createdAt ? moment(createdAt).format(Constants.DATETIME_FORMATS.DATETIME_TEXT) : null;

  const sourceTypeEmail = sourceType === SOURCE_TYPE_EMAIL;

  const [tooltipOpened, setTooltipOpened] = useState(false);

  const handleMouseEnter = useCallback(() => setTooltipOpened(true), []);

  const handleMouseLeave = useCallback(() => setTooltipOpened(false), []);

  return (
    <div
      className={Css.uploadedByCell}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <Avatar
        className={classNames(Css.avatar, sourceTypeEmail && Css.email)}
        iconComponent={sourceTypeEmail ? Icons.EnvelopeSimple : null}
        title={fullName || email}
        size="small" />
      <Tooltip
        contentClassName={classNames(Css.tooltip, sourceTypeEmail && Css.email)}
        place="left"
        opened={tooltipOpened}>
        <div className={Css.name}>{fullName || email}</div>
        {createdAtText && <div className={Css.date}>{createdAtText}</div>}
        {sourceTypeEmail && (
          <>
            {!!subject && (<div className={Css.subject}>
              <b>{`${uiTexts.subject}: `}</b>
              <span>{subject}</span>
            </div>)}
            {!!text && (<div className={Css.text}>
              <b>{`${uiTexts.text}: `}</b>
              <span>{text}</span>
            </div>)}
          </>
        )}
      </Tooltip>
    </div>
  );
};

export default React.memo(UploadedByCell);
