import { checkIsBusinessUser } from "selectors/user";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FieldContainer from "../FieldContainer";
import React, { useCallback } from "react";
import SelectLocationInput from "nlib/common/SelectLocationInput";
import useDocumentContext from "hooks/useDocumentContext";

const LocationField = (props) => {
  const id = "location";

  const { uiTexts } = useSelector(getTextsData);

  const {
    settings: { allowClientLocationSelection } = {}
  } = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const {
    documentState: { [id]: value },
    setDocumentState
  } = useDocumentContext();

  const handleChange = useCallback((newValue) => {
    setDocumentState({ [id]: newValue });
  }, [setDocumentState]);

  return (
    <FieldContainer
      id={id}
      disabled={businessUser && !allowClientLocationSelection}
      valid={!!value?.name && !!value?.id}
      invalid={!!value?.name && !value?.id}
      confidenceFields={["location"]}
      label={uiTexts.location}
      placeholder={uiTexts.selectLocation}
      value={value}
      displayValue={value?.id && value?.name}
      onChange={handleChange}
      component={SelectLocationInput}
      {...props} />
  );
};

export default React.memo(LocationField);
