import Css from "./style.module.scss";

import { checkDocumentsFetching } from "selectors/documents";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DocumentsStatuses from "nlib/pages/DocumentsPage/DocumentsStatuses";
import HeaderButtons from "../../../HeaderButtons";
import NoDataContent from "nlib/common/NoDataContent";
import Preloader from "nlib/common/Preloader";
import React, { useMemo } from "react";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const EmptyState = ({ documentsData, refetchDocuments }) => {
  const [{ text, fromDate, toDate, accountId, type, status }] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const fetchingData = useSelector(checkDocumentsFetching);

  const filtersEnabled = useMemo(() => {
    return [text, fromDate, toDate, accountId, type].find(Boolean);
  }, [text, fromDate, toDate, accountId, type]);

  const { labelLangId: statusLabelLangId } = useMemo(() => {
    return status
      ? DocumentsStatuses.getStatusData(status, activeOrganization.countryCode)
      : {};
  }, [status, activeOrganization]);

  const statusLabel = statusLabelLangId && uiTexts[statusLabelLangId].toLowerCase();

  const hasFilters = statusLabel && !filtersEnabled;

  return (
    <div className={Css.emptyState}>
      {fetchingData ? <Preloader /> : (
        <NoDataContent
          title={hasFilters
            ? Utils.replaceTextVars(uiTexts.noStatusDocuments, { status: statusLabel })
            : uiTexts.noDocuments}>
          {!hasFilters && (
            <HeaderButtons
              documentsData={documentsData}
              refetchDocuments={refetchDocuments} />
          )}
        </NoDataContent>
      )}

    </div>
  );
};

export default React.memo(EmptyState);
